/* eslint-disable max-lines-per-function */
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label
} from "@headlessui/react";
import cn from "classnames";
import {
  ChevronDownIcon, ChevronUpIcon, SearchIcon, XIcon
} from "lucide-react";
import { mapKeys, shake } from "radashi";
import {
  useEffect,
  useState
} from "react";
import { useController } from "react-hook-form";

const ComboField = ({
  className,
  control,
  label,
  name,
  options,
  setFocus,
  setValue,

  loadingMessage = "Lädt...",
  nothingFoundMessage = `Kein ${label} gefunden`,
  placeholder = `${label}...`
}) => {
  const {
    field: {
      disabled,
      onBlur,
      onChange,
      ref,
      value
    },
    fieldState: {
      error
    }
  } = useController({
    control,
    name
  });

  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const filterOptions = async (currentQuery) => {
    setIsLoading(true);
    setFilteredOptions(
      (
        typeof options === "function"
          ? await options(currentQuery)
          : options
      )
        .filter(({ name: optionName }) => (
          optionName
            .toLocaleLowerCase()
            .replaceAll(/\s+/gu, "")
            .includes(query.toLowerCase().replaceAll(/\s+/gu, ""))
        ))
    );

    setIsLoading(false);
  };

  const handleClose = () => {
    setQuery("");
    onBlur();
  };

  const openOptions = () => {
    if (!isOpen) {
      setFocus(name);
      setIsOpen(true);
    }
  };

  const closeOptions = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleChange = (event) => {
    closeOptions();
    handleClose();
    onChange(event);
  };

  const toggleOptions = () => {
    if (isOpen) {
      closeOptions();
    }
    else {
      openOptions();
    }
  };

  const handleBlur = (event) => {
    closeOptions();
    handleClose();
  };

  useEffect(() => {
    filterOptions(query);
  }, [query]);

  useEffect(() => {
    filterOptions("");
  }, [options]);

  const queryEmpty = query === "";

  const nothingFound = filteredOptions.length === 0 && !isLoading;

  return (
    <Combobox
      as="div"
      {
        ...mapKeys(
          shake(
            {
              closed: !isOpen,
              open: isOpen
            },
            (dataValue) => !dataValue
          ),
          (key) => `data-${key}`
        )
      }
      disabled={Boolean(disabled)}
      onChange={handleChange}
      onClose={handleClose}
      value={value}
      className={cn(
        "relative grid grid-cols-3 px-6 py-5 items-center justify-items-start gap-4 w-full group",
        className
      )}
    >
      <Label className="col-span-1 cursor-pointer text-sm font-medium text-gray-900" onClick={toggleOptions}>{label}</Label>

      <div className="contents" onClick={openOptions}>
        <div className="cursor-default! absolute left-2 col-start-2 border-r border-gray-300 pr-1">
          <SearchIcon className="size-5 text-gray-400" />
        </div>

        <ComboboxInput
          aria-label={label}
          displayValue={(option) => option?.name}
          onBlur={handleBlur}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={placeholder}
          ref={ref}
          className={cn(
            "flex h-10 items-center text-sm rounded-xs pl-10 pr-14.5 col-span-2 placeholder-gray-300 w-full",
            {
              "bg-gray-200": disabled,
              "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !error,
              "border-red-500 focus:ring-red-700 focus:border-red-700": error
            }
          )}
        />

        {
          isOpen
            ? null
            : (
              <div
                className="cursor-default! right-7.5  absolute border-l border-gray-300 pl-1"
                onClick={closeOptions}
              >
                <ChevronDownIcon className="size-5 text-gray-400" />
              </div>
            )
        }

      </div>

      {
        isOpen
          ? (
            <div
              className="cursor-default! right-7.5  absolute border-l border-gray-300 pl-1"
              onClick={openOptions}
            >
              <ChevronUpIcon className="size-5 text-gray-400" />
            </div>
          )
          : null
      }

      {
        value
          ? (
            <>
              <div className="top-6.25 pointer-events-none absolute left-8 col-span-1 col-start-2 truncate rounded-sm border border-transparent bg-gray-700 px-2 py-1 text-sm text-white group-data-[open]:hidden" >{value.name}</div>

              <button
                className="cursor-default! right-14.5 absolute"
                type="button"
                onClick={() => {
                  setValue(name, null);
                }}
              >
                {" "}

                <XIcon className="size-5 text-gray-400" />
              </button>
            </>

          )
          : null
      }

      <ComboboxOptions anchor="bottom" className="z-40 mt-2 w-[var(--input-width)] overflow-hidden rounded-sm border border-gray-300 bg-white text-base shadow-lg" modal={false} static={isOpen}>
        {
          nothingFound
            ? (
              <span
                className="flex w-full select-none justify-center px-4 py-2 text-gray-300"
              >
                {nothingFoundMessage}
              </span>
            )
            : null
        }

        {
          isLoading
            ? (
              <span
                className="flex w-full select-none justify-center px-4 py-2 text-gray-300"
              >
                {loadingMessage}
              </span>
            )
            : filteredOptions.map((option) => (
              <ComboboxOption
                className="select-none truncate px-4 py-2 data-[focus]:bg-gray-600 data-[selected]:bg-gray-700 data-[focus]:text-white data-[selected]:text-white"
                key={option.id}
                value={option}
              >
                {option.name}
              </ComboboxOption>
            ))
        }

      </ComboboxOptions>

      {
        error
          ? (
            <p className="absolute top-2 col-span-3 col-start-2 row-start-2 text-sm text-red-600">
              {error.message}
            </p>
          )
          : null
      }
    </Combobox>
  );
};

export default ComboField;
