const ProjectItemSkeleton = ({ className }) => (
  <div className={className}>

    <div className="animate-pulse p-4 sm:px-6">
      <div className="flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>

      <div className="mt-2 flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>
    </div>

    <div className="animate-pulse p-4 sm:px-6">
      <div className="flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>

      <div className="mt-2 flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>
    </div>

    <div className="animate-pulse p-4 sm:px-6">
      <div className="flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>

      <div className="mt-2 flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>
    </div>

    <div className="animate-pulse p-4 sm:px-6">
      <div className="flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>

      <div className="mt-2 flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>
    </div>

    <div className="animate-pulse p-4 sm:px-6">
      <div className="flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>

      <div className="mt-2 flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>
    </div>

    <div className="animate-pulse p-4 sm:px-6">
      <div className="flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>

      <div className="mt-2 flex justify-between space-x-4">
        <div className="h-4 w-32 rounded-sm bg-gray-300" />

        <div className="h-4 w-32 rounded-sm bg-gray-300" />
      </div>
    </div>

  </div>
);

export default ProjectItemSkeleton;
