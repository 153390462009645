/**
 *
 * @param props0 - The root object
 * @param props0.eventName - The root object
 * @param props0.filter - The root object
 * @param props0.handleUpdate - The root object
 * @param props0.label - The root object
 * @param props0.options - The root object
 * @example
 */
const FilterEvent = ({
  eventName, filter, handleUpdate, label, options
}) => {
  /**
   *
   * @param event
   * @example
   */
  function handleChange(event) {
    handleUpdate(eventName, event.target.value);
  }

  return (
    <div className="md:w-64">
      <label className="block text-sm font-medium text-gray-700">{label}</label>

      <select
        className="mt-1 block w-full rounded-md border-gray-300 py-1 pl-3 pr-10 text-sm focus:border-indigo-500 focus:outline-hidden focus:ring-indigo-500 sm:text-xs"
        onChange={handleChange}
        value={filter}
      >
        {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
      </select>
    </div>
  );
};

export default FilterEvent;
