import { useParams } from "react-router-dom";

import ConstructionTimeline from "~/src/features/project-timeline/construction-timeline.jsx";
import EditTimelineSlide from "~/src/features/project-timeline/edit-timeline-slide.jsx";
import MarketingTimeline from "~/src/features/project-timeline/marketing-timeline.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const ProjectTimelinePage = () => {
  const { id } = useParams();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { user } = useAuth();
  const { editPossible } = useEdit({
    project,
    user
  });
  const {
    data: editData, hide: hideEdit, show: showEdit, visible: editVisible
  } = useModal();

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  return (
    <div>
      <EditTimelineSlide hide={hideEdit} project={editData} projectId={id} visible={editVisible} />

      <div className="mb-8 flex justify-between">
        <h2 className="mb-4 text-xl font-medium text-gray-800">Timeline</h2>

        {showEditActions
          ? (
            <div>
              <SubmitButton onClick={() => showEdit(project)}>
                <EditIcon className="size-5" />
              </SubmitButton>
            </div>
          )
          : null}
      </div>

      <div className="divide-y divide-gray-200">
        <ConstructionTimeline project={project} />

        <MarketingTimeline project={project} />
      </div>

    </div>
  );
};

export default ProjectTimelinePage;
