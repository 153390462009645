import { Transition } from "@headlessui/react";
import {
  useEffect, useState
} from "react";

import useNotification from "~/src/hooks/use-notification.js";

/**
 *
 * @example
 */
const ErrorNotification = () => {
  const { error, hideError } = useNotification();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        hideError(false);
      }, 3_500);
    }
  }, [error, hideError]);

  return (
    <div className="z-1000 pointer-events-none fixed inset-0 px-4 py-6 sm:p-6">
      <Transition
        as="div"
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={visible}
      >
        <div className="flex items-end justify-center px-4 py-6 sm:items-start sm:justify-end">

          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border-2 border-red-300 bg-white shadow-lg ring-1 ring-black/5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  <svg aria-hidden="true" className="size-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                  </svg>
                </div>

                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-red-800">
                    Fehler!
                  </p>

                  <p className="mt-1 text-sm text-red-700">
                    Die Aktion wurde nicht durchgeführt.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Transition>
    </div>
  );
};

export default ErrorNotification;
