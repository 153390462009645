import { pick } from "radashi";

import Handler from "~/src/modules/handler.js";

/**
 * Handler for project certificate CRUD operations
 */
const handler = new Handler({

  /**
   * API endpoint for project certificates
   */
  endpoint: "/project-certificates/:id",

  /**
   * Callback executed after every operation
   *
   * @param {object} params - Operation parameters
   * @param {Function} params.setIsLoading - Loading state setter
   * @example
   */
  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (
    responseData,
    {
      hide,
      mutate,
      showSuccess
    }
  ) => {
    await mutate();
    showSuccess();
    hide();
  },

  transformCreateData: (
    {
      assigned_points,
      issuer,
      issuer_certificate,
      ...projectCertificate
    },
    {
      projectId
    }
  ) => ({
    ...pick(projectCertificate, ["assigned_points", "notes"]),
    assigned_points: issuer.has_points
      ? assigned_points
      : null,
    issuer_certificate_id: issuer.has_certificates && issuer_certificate
      ? issuer_certificate.id
      : null,
    issuer_id: issuer.id,
    project_id: projectId
  }),

  transformUpdateData: (
    {
      assigned_points,
      issuer,
      issuer_certificate,
      ...projectCertificate
    },
    {
      projectId
    }
  ) => ({
    ...pick(
      projectCertificate,
      [
        "id",
        "assigned_points",
        "notes"
      ]
    ),
    assigned_points: issuer.has_points
      ? assigned_points
      : null,
    issuer_certificate_id: issuer.has_certificates && issuer_certificate
      ? issuer_certificate.id
      : null,
    issuer_id: issuer.id,
    project_id: projectId
  })
});

export default handler;
