import { useHistory, useLocation } from "react-router-dom";
const ReactRouter5Adapter = ({
  children
}) => {
  const location = useLocation();
  const history = useHistory();
  const adapter = {
    replace(location2) {
      history.replace(location2.search || "?", location2.state);
    },
    push(location2) {
      history.push(location2.search || "?", location2.state);
    },
    get location() {
      return history.location;
    }
  };
  return children(adapter);
};
export {
  ReactRouter5Adapter
};
