export { default as ProjectAttributesPage } from "./project-attributes.jsx";
export { default as ProjectCompaniesPage } from "./project-companies.jsx";
export { default as ProjectContractsPage } from "./project-contracts.jsx";
export { default as ProjectDocumentsPage } from "./project-documents.jsx";
export { default as ProjectInfosPage } from "./project-infos.jsx";
export { default as ProjectOverviewPage } from "./project-overview.jsx";
export { default as ProjectSettingsPage } from "./project-settings.jsx";
export { default as ProjectTimelinePage } from "./project-timeline.jsx";
export { default as ProjectTransactionsPage } from "./project-transactions.jsx";
export { default as ProjectUnitsPage } from "./project-units.jsx";
