/**
 *
 * @param props0 - The root object
 * @param props0.onClick - The root object
 * @param props0.value - The root object
 * @example
 */
const AddPublicName = ({ onClick, value }) => {


  /**
   *
   * @example
   */
  function handleClick() {
    onClick(value);
  }

  return (
    <li>
      <button className="hover:text-gray-500 focus:outline-hidden" onClick={handleClick} type="button">
        {value}
      </button>
    </li>
  );
};

export default AddPublicName;
