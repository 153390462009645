import Handler from "~/src/modules/handler.js";

const {
  handleCreate
} = new Handler({
  endpoint: "/users",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { setBackendValidationError, showError }, backendValidationErrors) => {
    if (backendValidationErrors.length > 0) {
      const parsedValidationErrorObject = Object.fromEntries(backendValidationErrors.map((error) => {
        const [,errorField] = error.path.split(".");

        const validationObject = {
          code: `error.${errorField}`,
          message: `${error.value} - ${error.msg}`
        };

        return [errorField, validationObject];
      }));

      setBackendValidationError(parsedValidationErrorObject);
    }

    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data) => ({
    user: {
      email: data.email,
      password: data.password,
      role: data.role.value,
      username: data.username,
      username_short: data.username_short
    }
  })
});

export {
  handleCreate
};
