import cn from "classnames";

import { formatArea, formatEuro } from "~/src/modules/formatters.new.js";

const directions = ["top", "bottom"];

/**
 *
 * @param props0 - The root object
 * @param props0.label - The root object
 * @param props0.showEditActions - The root object
 * @param props0.showSlide - The root object
 * @param props0.statistic - The root object
 * @param props0.statisticKey - The root object
 * @example
 */
const TopBottom = ({
  label, showEditActions, showSlide, statistic, statisticKey
}) => (
  <li className={cn("bg-gray-100 p-4 rounded-sm shadow-sm space-y-4")} key={statisticKey}>
    <h3 className="text-lg font-medium text-gray-800">{label}</h3>

    <ul
      className="grid grid-cols-2 gap-2"
    >
      {
        directions.every((direction) => statistic?.[direction].length === 0)
          ? (
            <li className="col-span-2">
              <p className="text-gray-400">Kein Eintrag</p>
            </li>
          )
          : (
            ["green", "red"]
              .map((color, index) => [directions[index], color])
              .map(([direction, color]) => (
                <li key={direction}>
                  <ul className="flex flex-col gap-2">
                    {
                      statistic?.[direction]
                        .map(({
                          id, data, name: unitName, smape, value
                        }, index) => {
                          const opacity = `${(smape / 2) * 100}%`;
                          const adjustedOpacity = `${((smape + 1) / 2) * 100}%`;

                          return (
                            <li
                              key={index}
                              className={cn(
                                "group flex gap-2 justify-between items-center p-2 border rounded-sm relative ",
                                direction,
                                {
                                  "bg-emerald-700/(--button-bg-opacity) border-emerald-700": color === "green",
                                  "bg-red-700/(--button-bg-opacity) border-red-700": color === "red",
                                  "cursor-pointer": showEditActions,
                                  "hover:bg-emerald-700/[calc(var(--button-bg-opacity)+10%)] hover:border-emerald-700": color === "green" && showEditActions,
                                  "hover:bg-red-700/[calc(var(--button-bg-opacity)+10%)] hover:border-red-700": color === "red" && showEditActions
                                }
                              )}
                              onClick={() => {
                                if (showEditActions) {
                                  showSlide(data);
                                }
                              }}
                              style={{
                                "--button-bg-opacity": opacity,
                                "--pill-bg-opacity": adjustedOpacity
                              }}
                            >
                              <h4
                                className={cn(
                                  "text-ellipsis whitespace-nowrap overflow-hidden transition-opacity duration-500 ",
                                  {
                                    "text-emerald-900": color === "green",
                                    "text-red-900": color === "red"
                                  }
                                )}
                              >
                                {unitName}
                              </h4>

                              <span
                                className={cn(
                                  "text-white font-medium whitespace-nowrap overflow-hidden rounded-sm px-1 py-0.5 transition-opacity duration-500 min-w-max font-mono text-sm",
                                  {
                                    "bg-emerald-700/(--pill-bg-opacity)": color === "green",
                                    "bg-red-700/(--pill-bg-opacity)": color === "red"
                                  }
                                )}
                              >
                                {
                                  statisticKey.includes("Area")
                                    ? formatArea(value)
                                    : formatEuro(value)
                                }
                              </span>
                            </li>
                          );
                        })
                    }
                  </ul>
                </li>
              ))
          )
      }
    </ul>
  </li>
);

export default TopBottom;
