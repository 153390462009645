import { sum } from "lodash-es";
import { ArrowRightLeftIcon } from "lucide-react";
import { useEffect } from "react";

import displayDiff from "~/src/modules/format-activity-change/helpers/display-diff.jsx";
import { pluralize } from "~/src/modules/formatters.new.js";

import UnitFormSlide from "~/src/features/project-units/unit-form-slide.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";
import useUnit from "~/src/hooks/use-unit.js";

import EditIcon from "~/src/ui/icons/edit-icon.jsx";

const buyerTypeLabels = new Map([["company", "Firmenkäufer"], ["private", "Privatkäufer"]]);

const buyerLabels = new Map([
  [
    "company",
    new Map([
      ["city", "Stadt"],
      ["country", "Land"],
      ["house_number", "Hausnummer"],
      ["is_zvr", "Verein"],
      ["name", "Name"],
      ["no_reg_number", "Keine FB- oder ZVR-Nr."],
      ["notes", "Notizen"],
      ["reg_number", "FB- oder ZVR-Nr."],
      ["show", "Käufer anzeigen"],
      ["street", "Straße"],
      ["zipcode", "Postleitzahl"]
    ])
  ],
  [
    "private",
    new Map([
      ["birthdate", "Geburtsdatum"],
      ["city", "Stadt"],
      ["country", "Land"],
      ["house_number", "Hausnummer"],
      ["name", "Vorname"],
      ["notes", "Notizen"],
      ["show", "Käufer anzeigen"],
      ["street", "Straße"],
      ["surname", "Nachname"],
      ["title_prefix", "Titel-Präfix"],
      ["title_suffix", "Titel-Suffix"],
      ["zipcode", "Postleitzahl"]
    ])
  ]
]);

const UnitBuyerDifferencesListProjectUnit = ({
  id,
  differences,
  editPossible,
  kvId,
  mutate,
  name,
  projectId
}) => {
  const { user } = useAuth();
  const { project } = useProject(projectId);

  const { editUnitPossible } = useEdit({
    project,
    user
  });
  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  const numberOfDifferences = sum(Object.entries(differences).map(([buyerType, innerDifferences]) => innerDifferences.length));

  const { mutate: mutateUnit, unit } = useUnit(id);

  useEffect(() => {
    mutateUnit();
  }, [differences]);

  return (
    <li className="flex flex-col gap-2 rounded-sm border border-gray-400 bg-gray-100 p-2">
      <UnitFormSlide
        buildingParts={project?.building_parts}
        mutateUnits={mutate}
        projectId={projectId}
        unit={slideData}
        visible={slideVisible}
        hide={() => {
          hideSlide();
        }}
      />

      <div className="flex w-full items-center justify-between">
        <span className="flex w-1/3 items-center gap-2">
          <span className="text-sm">{name}</span>

          <span className="rounded-md bg-gray-300 px-2 text-xs font-bold capitalize text-gray-900">ID: {id}</span>
        </span>

        <span className="w-1/3 text-center text-xs text-gray-400">{numberOfDifferences} {pluralize("Käufer", "Käufer", numberOfDifferences)} betroffen</span>

        <div className="flex w-1/3 items-center justify-end gap-2">
          <a className="text-xs underline" href={`https://kvea.immounited.com/?DocumentId=${kvId}`}>KV-ID: {kvId}</a>

          {
            (editPossible && editUnitPossible)
              ? (
                <button
                  className="inline-flex shrink-0 items-center justify-center text-gray-500 hover:text-gray-400 focus:outline-hidden"
                  type="button"
                  onClick={() => {
                    showSlide(unit);
                  }}
                >
                  <EditIcon className="size-5" />
                </button>
              )
              : null
          }

        </div>
      </div>

      <ul className="flex flex-col gap-1">
        {
          Object.entries(differences)
            .map(([buyerType, innerDifferences]) => (
              <li className="flex flex-col gap-1 rounded-sm bg-gray-100" key={buyerType}>
                <span className="text-xs">{buyerTypeLabels.get(buyerType)}</span>

                <ul className="flex flex-col gap-0.5">
                  {
                    innerDifferences
                      .map(({
                        before, after, changes
                      }, index) => (
                        <li className="flex flex-col gap-0.5 rounded-sm bg-gray-100 p-1" key={index}>
                          <span className="text-xs text-gray-400">Käufer # {index + 1}</span>

                          <ul className="flex flex-col gap-0.5">
                            {
                              changes
                                .map((key) => {
                                  const {
                                    before: beforeValue,
                                    after: afterValue
                                  } = Object.fromEntries(
                                    Object.entries({
                                      before,
                                      after
                                    })
                                      .map(([sideKey, { [key]: sideValue }]) => [sideKey, sideValue])
                                  );
                                  const {
                                    before: beforeDisplay,
                                    after: afterDisplay
                                  } = Object.fromEntries(
                                    ["before", "after"]
                                      .map((side) => [
                                        side,
                                        displayDiff(
                                          side === "before"
                                            ? beforeValue
                                            : afterValue,
                                          side === "before"
                                            ? afterValue
                                            : beforeValue,
                                          side,
                                          "neutral"
                                        )
                                      ])
                                  );

                                  return (
                                    <li className="flex items-stretch gap-0.5 rounded-sm border border-gray-400 bg-gray-50 text-sm" key={key}>
                                      <span className="w-[25%] hyphens-auto break-words bg-gray-400 p-1 text-white">
                                        {buyerLabels.get(buyerType)?.get(key)}
                                      </span>

                                      <span className="w-[35%] hyphens-auto break-words p-1 text-start font-mono">
                                        {beforeDisplay}
                                      </span>

                                      <span className="flex w-[5%] items-center justify-center bg-gray-400 p-1">
                                        <ArrowRightLeftIcon className="h-5 text-gray-300" />
                                      </span>

                                      <span className="w-[35%] hyphens-auto break-words p-1 text-end font-mono">
                                        {afterDisplay}
                                      </span>
                                    </li>
                                  );
                                })
                            }
                          </ul>
                        </li>
                      ))
                  }
                </ul>
              </li>
            ))
        }
      </ul>
    </li>
  );
};

export default UnitBuyerDifferencesListProjectUnit;
