import "temporal-polyfill/global";
import queryString from "query-string";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import Nav from "~/src/features/nav/index.jsx";

import ProtectedRoute from "~/src/ui/routes/protected-route/index.jsx";

import {
  ActivitiesPage,
  AutomatedProjectPage,
  AutomatedProjectsPage,
  CertificateIssuersPage,
  CompaniesPage,
  ContractsPage,
  ErrorPage,
  FeedPage,
  LoginPage,
  NotFoundPage,
  PersonNamesPage,
  ProjectPage,
  ProjectsPage,
  UnitBuyerDifferencesPage,
  UsersPage
} from "./pages/_exports.js";

/**
 *
 * @example
 */
const App = () => (
  <Router>
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={{
        objectToSearchString: queryString.stringify,
        searchStringToObject: queryString.parse
      }}
    >
      <div>
        <Nav />

        <Switch>

          <ProtectedRoute exact path="/">
            <Redirect to="projects" />
          </ProtectedRoute>

          <Route path="/login">
            <LoginPage />
          </Route>

          <ProtectedRoute exact path="/projects">
            <ProjectsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/projects/:id">
            <ProjectPage />
          </ProtectedRoute>

          <ProtectedRoute path="/users">
            <UsersPage />
          </ProtectedRoute>

          <ProtectedRoute path="/persons">
            <PersonNamesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/feed">
            <FeedPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/unit-buyer-differences">
            <UnitBuyerDifferencesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/contracts">
            <ContractsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/companies">
            <CompaniesPage />
          </ProtectedRoute>

          <ProtectedRoute path="/certificates">
            <CertificateIssuersPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/activities">
            <ActivitiesPage />
          </ProtectedRoute>

          <ProtectedRoute path="/companies">
            <CompaniesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/automated-projects">
            <AutomatedProjectsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/automated-projects/:id">
            <AutomatedProjectPage />
          </ProtectedRoute>

          <Route path="/error">
            <ErrorPage />
          </Route>

          <Route path="*">
            <NotFoundPage />
          </Route>

        </Switch>
      </div>
    </QueryParamProvider>
  </Router>
);

export default App;
