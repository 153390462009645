/**
 *
 * @param props0 - The root object
 * @param props0.status - The root object
 * @example
 */
const NotFoundPage = ({ status = 404 }) => (
  <div className="items flex h-[calc(100vh-4rem)] flex-col items-center justify-center gap-8 text-2xl font-bold md:flex-row">
    <span className="border-b border-r-0 border-gray-200 p-8 text-8xl text-gray-500 md:border-b-0 md:border-r ">{status}</span>

    <span className="text-4xl font-bold">Seite nicht gefunden</span>
  </div>
);

export default NotFoundPage;
