import cn from "classnames";

import innerText from "~/src/modules/inner-text.js";

import InlineInput from "~/src/ui/forms/inline-input/index.new.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.compact - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.hasLabel - The root object
 * @param props0.inline - The root object
 * @param props0.isDetailsView - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.outerPrefix - The root object
 * @param props0.outerSuffix - The root object
 * @param props0.placeholder - The root object
 * @param props0.prefix - The root object
 * @param props0.range - The root object
 * @param props0.register - The root object
 * @param props0.suffix - The root object
 * @param props0.type - The root object
 * @param props0.icon - The root object
 * @param props0.iconPosition - The root object
 * @example
 */
const Field = ({
  autoFocus = false,
  className,
  compact,
  disabled = false,
  errors,
  hasLabel = true,
  icon,
  iconPosition = "left",
  inline,
  isDetailsView = false,
  label,
  name,
  onChange = () => { },
  outerPrefix,
  outerSuffix,
  prefix,
  range,
  register,
  suffix,
  type = "search",

  id = `${name}${Date.now()}`,
  placeholder = innerText(label)
}) => (
  <div className={cn("py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200", className)}>
    <div
      className={cn(
        "sm:grid sm:gap-x-4",
        {
          "p-0": inline,
          "py-6 px-6": !inline && hasLabel,
          "sm:grid-cols-3": !compact,
          "sm:grid-cols-6": compact
        }
      )}
    >
      {hasLabel
        ? (
          <div className={inline ? "col-span-3" : "col-span-1"}>
            <label
              htmlFor={id}
              className={cn(
                "inline-block text-sm font-medium sm:mt-px sm:pt-2",
                {
                  "cursor-not-allowed": disabled,
                  "cursor-pointer": !disabled,
                  "text-gray-700 text-base font-normal ": isDetailsView,
                  "text-gray-900": !isDetailsView
                }
              )}
            >
              {label}
            </label>
          </div>
        )
        : null}

      {outerPrefix}

      <div
        className={cn(
          "relative grid grid-cols-3",
          {
            "col-span-2": !compact,
            "col-span-3": inline && !compact,
            "col-span-5": compact,
            "col-span-6": !hasLabel
          }
        )}
      >
        {prefix}

        <InlineInput
          {...{
            id,
            autoFocus,
            disabled,
            errors,
            icon,
            iconPosition,
            isDetailsView,
            name,
            onChange,
            placeholder,
            register,
            type
          }}
          className={cn("w-full", {
            "col-span-2": prefix,
            "col-span-3": !prefix
          })}
        />

        {suffix}
      </div>

      {outerSuffix}
    </div>
  </div>
);

export default Field;
