import Joi from "joi";

import { roleLabels } from "~/src/modules/labels.jsx";

const initialValues = {
  email: "",
  password: "",
  role: {
    label: "",
    value: ""
  },
  username: "",
  username_short: ""
};

/**
 *
 * @param user
 * @example
 */
const generateEditValues = (user) => {
  if (user) {
    return {
      email: user.email,
      password: "",
      role: {
        label: roleLabels[user.role],
        value: user.role || "inactive"
      },
      username: user.username,
      username_short: user.username_short
    };
  }

  return initialValues;
};

const schema = Joi.object({
  email: Joi.string().empty("").required(),
  password: Joi.string().empty("").min(4)
    .max(40)
    .optional(),
  role: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("").required()
  }),
  username: Joi.string().empty("").required(),
  username_short: Joi.string().empty("").min(4)
    .max(6)
    .required()
});

export {
  generateEditValues,
  schema
};
