import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.isDetailsView - The root object
 * @param props0.name - The root object
 * @param props0.placeholder - The root object
 * @param props0.register - The root object
 * @param props0.rows - The root object
 * @param props0.type - The root object
 * @param props0.resizable - The root object
 * @example
 */
const InputArea = ({
  autoFocus = false,
  className,
  disabled = false,
  errors,
  isDetailsView = false,
  name,
  placeholder = "",
  register,
  resizable = false,
  rows = 3,
  type = "search",

  id = name
}) => {
  const showError = Boolean(errors[name]);

  return (
    <>
      <div className={cn("relative", className)}>
        <textarea
          {...register(name)}
          {
            ...{
              id,
              autoFocus,
              disabled,
              placeholder,
              rows,
              type
            }
          }
          className={cn({
            "bg-gray-200 cursor-not-allowed": disabled && !isDetailsView,
            "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError && !isDetailsView,
            "border-red-500 focus:ring-red-700 focus:border-red-700": showError,
            "resize-none": !resizable,
            "shadow-none block w-full border-none rounded-xs placeholder-gray-300 px-0": isDetailsView,
            "shadow-xs block w-full focus:ring-gray-700 focus:border-gray-700 sm:text-sm border-gray-300 rounded-xs placeholder-gray-300": !isDetailsView
          })}
        />

        {showError
          ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
              </svg>
            </div>
          )
          : null}
      </div>

      {showError ? <p className="col-span-3 mt-2 text-sm text-red-600">{errors[name].message}</p> : null}
    </>
  );
};

export default InputArea;
