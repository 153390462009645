import { useState } from "react";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";

import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import InputArea from "~/src/ui/forms/input-area/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";

import { handleCreate } from "./handlers.js";
import { initialValues, schema } from "./schema.js";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.mutateCompanies - The root object
 * @param props0.visible - The root object
 * @example
 */
const NewCompanyModal = ({
  hide, mutateCompanies = () => null, visible
}) => {
  const { showError } = useNotification();
  const [error, setError] = useState(false);

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      setError(false);
      handleCreate({
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate: mutateCompanies,
        setError,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <Modal
      show={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <div className="inline-block w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle" role="dialog">
        <h3 className="text-lg font-medium">Neues Unternehmen hinzufügen</h3>

        {error ? <p className="mt-6 text-red-500">Firmenname oder Firmenbuchnummer existiert bereits!</p> : null}

        <form autoComplete="off" onSubmit={form.handleSubmit}>
          <div className="mt-6">
            <InlineCheckbox
              error={form.errors.searchable}
              id="searchable"
              isSubmitting={form.isSubmitting}
              label="Unternehmen suchbar?"
              name="searchable"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              touched={form.touched.searchable}
              value={form.values.searchable}
            />
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="name">
              {form.values.is_zvr ? "Vereinsname" : "Firmenname"}
            </label>

            <div className="mt-1">
              <InlineInput
                autoFocus
                error={form.errors.name}
                id="name"
                isSubmitting={form.isSubmitting}
                name="name"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.name}
                value={form.values.name}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="reg_number">
              {form.values.is_zvr ? "ZVR-Nr." : "Firmenbuch-Nr."}
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.reg_number}
                id="reg_number"
                isSubmitting={form.isSubmitting}
                name="reg_number"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.reg_number}
                value={form.values.reg_number}
              />
            </div>
          </div>

          <div className="mt-6">
            <InlineCheckbox
              error={form.errors.no_reg_number}
              id="no_reg_number"
              isSubmitting={form.isSubmitting}
              label="Keine Firmenbuch- oder ZVR-Nr."
              name="no_reg_number"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              touched={form.touched.no_reg_number}
              value={form.values.no_reg_number}
            />

            <InlineCheckbox
              error={form.errors.is_zvr}
              id="is_zvr"
              isSubmitting={form.isSubmitting}
              label="Verein"
              name="is_zvr"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              touched={form.touched.is_zvr}
              value={form.values.is_zvr}
            />
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="internal_notes">
              Interne Bemerkungen
            </label>

            <div className="mt-1">
              <InputArea
                error={form.errors.internal_notes}
                isSubmitting={form.isSubmitting}
                name="internal_notes"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.internal_notes}
                value={form.values.internal_notes || ""}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="street">
              Straße
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.street}
                id="street"
                isSubmitting={form.isSubmitting}
                name="street"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.street}
                value={form.values.street}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="house_number">
              Hausnummer
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.house_number}
                id="house_number"
                isSubmitting={form.isSubmitting}
                name="house_number"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.house_number}
                value={form.values.house_number}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="zipcode">
              PLZ
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.zipcode}
                id="zipcode"
                isSubmitting={form.isSubmitting}
                name="zipcode"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.zipcode}
                value={form.values.zipcode}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="city">
              Stadt
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.city}
                id="city"
                isSubmitting={form.isSubmitting}
                name="city"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.city}
                value={form.values.city}
              />
            </div>
          </div>

          <div className="mt-6 ">
            <label className="block text-sm font-medium text-gray-700" htmlFor="additional_address_info">
              Addresszusatz
            </label>

            <div className="mt-1">
              <InlineInput
                error={form.errors.additional_address_info}
                id="additional_address_info"
                isSubmitting={form.isSubmitting}
                name="additional_address_info"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.additional_address_info}
                value={form.values.additional_address_info}
              />
            </div>
          </div>

          <div className="mt-6">
            <InlineCheckbox
              error={form.errors.foreign_country}
              id="foreign_country"
              isSubmitting={form.isSubmitting}
              label="Ausland?"
              name="foreign_country"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              touched={form.touched.foreign_country}
              value={form.values.foreign_country}
            />
          </div>

          {form.values.foreign_country
            ? (
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="country">
                  Land
                </label>

                <div className="mt-1">
                  <InlineInput
                    error={form.errors.country}
                    id="country"
                    isSubmitting={form.isSubmitting}
                    name="country"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    touched={form.touched.country}
                    value={form.values.country}
                  />
                </div>
              </div>
            )
            : null}

          <div className="mt-5 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <FormSubmitButton className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-700 px-4 py-2 text-base font-medium text-white shadow-xs hover:bg-gray-800 focus:outline-hidden focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 sm:col-start-2 sm:text-sm" isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>

            <button
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-xs hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              type="button"
              onClick={() => {
                handleHide(form);
              }}
            >
              abbrechen
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewCompanyModal;
