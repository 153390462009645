import cn from "classnames";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import AreaField from "~/src/ui/forms/area-field/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import CollectionField from "~/src/ui/forms/collection-field/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import DateField from "~/src/ui/forms/date-field/index.jsx";
import MultiField from "~/src/ui/forms/multi-field/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.new.jsx";

import initialValues from "../../_common/initial-values.js";

/**
 *
 * @param props0 - The root object
 * @param props0.colorKeys - The root object
 * @param props0.isEdit - The root object
 * @param props0.onSubmit - The root object
 * @example
 */
const OfferForm = ({
  colorKeys = [], isEdit, onSubmit
}) => {
  const {
    clearErrors, control, formState: { errors }, handleSubmit, register, resetField, setValue, watch
  } = useFormContext();

  const verwertetDateReference = useRef();

  const verwertet = watch("verwertet");
  const rentable = watch("rentable");

  const showRentNettoHistory = watch("showRentNettoHistory");
  const showRentBruttoHistory = watch("showRentBruttoHistory");
  const showRentBkHistory = watch("showRentBkHistory");

  const buyable = watch("buyable");

  const showOfferPriceNormalHistory = watch("showOfferPriceNormalHistory");
  const showOfferPriceInvestorHistory = watch("showOfferPriceInvestorHistory");
  const showOfferPriceBruttoInvestorHistory = watch("showOfferPriceBruttoInvestorHistory");

  useEffect(() => {
    if (errors.verwertetDate) {
      verwertetDateReference.current.scrollIntoView();
    }
  }, [errors]);

  return (
    <div className="border-primary-brown border-t-2 pt-6">
      <h2 className="ml-6 text-xl font-medium">Angebot</h2>

      {/* --- verwertet --- */}

      <CheckboxField
        label="Objekt verwertet"
        name="verwertet"
        className={cn(
          "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
          {
            "bg-amber-500": colorKeys.includes("verwertet")
          }
        )}
        {...{
          errors,
          register
        }}
      />

      {verwertet
        ? (
          <div className="m-4 rounded-sm bg-white/50" ref={verwertetDateReference}>
            <DateField
              label="Verwertungsdatum"
              name="verwertetDate"
              {...{
                control,
                errors,
                register
              }}
              defaultValue={initialValues.verwertetDate}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
              minDate={new Date("2000-01-01")}
              className={cn(
                "col-span-1",
                {
                  "bg-amber-500": colorKeys.includes("verwertetDate")
                }
              )}
              onChange={(e) => {
                handleSubmit(
                  () => { },
                  (innerErrors) => {
                    clearErrors(Object.keys(innerErrors).filter((key) => key !== "verwertetDate"));
                  }
                )({ verwertetDate: e });
              }}
            />

            <ComboField
              defaultOptions
              label="Verwertet-Datumsformat"
              name="verwertetDateFormat"
              messages={{
                queryEmpty: "Verwertet-Datumsformat..."
              }}
              options={[
                {
                  label: "Tag",
                  value: "day"
                },
                {
                  label: "Monat",
                  value: "month"
                },
                {
                  label: "Quartal",
                  value: "quarter"
                },
                {
                  label: "Jahr",
                  value: "year"
                }
              ]}
              {...{
                control,
                isEdit,
                resetField,
                setValue,
                watch
              }}
              className={cn(
                "col-span-6",
                {
                  "bg-amber-500": colorKeys.includes("verwertetDateFormat")
                }
              )}
            />
          </div>
        )
        : null}

      {/* --- rentable --- */}

      <CheckboxField
        label="Mietpreise vorhanden"
        name="rentable"
        className={cn(
          "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
          {
            "bg-amber-500": colorKeys.includes("rentable")
          }
        )}
        {...{
          errors,
          register
        }}
      />

      {rentable
        ? (
          <div className="m-4 bg-white/50">
            <NumberField
              label="Miete Netto"
              name="rentNetto"
              className={cn(
                "col-span-3",
                {
                  "bg-amber-500": colorKeys.includes("rentNetto")
                }
              )}
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showRentNettoHistory"
              className={cn(
                "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
                {
                  "bg-amber-500": colorKeys.includes("showRentNettoHistory")
                }
              )}
              {...{
                errors,
                register
              }}
            />

            {showRentNettoHistory
              ? (
                <div
                  className={cn(
                    "m-4 inline-block rounded-sm",
                    {
                      "bg-amber-500/50": colorKeys.includes("rentNettoHistory")
                    }
                  )}
                >

                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Miete Netto</>}
                    name="rentNettoHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              label={<>Miete <span className="ml-0.5 font-bold text-indigo-600">Netto + USt</span></>}
              name="rentBrutto"
              className={cn(
                "col-span-3",
                {
                  "bg-amber-500": colorKeys.includes("rentBrutto")
                }
              )}
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showRentBruttoHistory"
              className={cn(
                "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
                {
                  "bg-amber-500": colorKeys.includes("showRentBruttoHistory")
                }
              )}
              {...{
                errors,
                register
              }}
            />

            {showRentBruttoHistory
              ? (
                <div
                  className={cn(
                    "m-4 inline-block",
                    {
                      "bg-amber-500/50": colorKeys.includes("rentBruttoHistory")
                    }
                  )}
                >

                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Miete <span className="ml-0.5 font-bold text-indigo-600">Netto + USt</span></>}
                    name="rentBruttoHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              label={<>Miete <span className="ml-1 font-bold text-green-500">Gesamtbelastung</span></>}
              name="rentBk"
              className={cn(
                "col-span-3",
                {
                  "bg-amber-500": colorKeys.includes("rentBk")
                }
              )}
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showRentBkHistory"
              className={cn(
                "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
                {
                  "bg-amber-500": colorKeys.includes("showRentBkHistory")
                }
              )}
              {...{
                errors,
                register
              }}
            />

            {showRentBkHistory
              ? (
                <div
                  className={cn(
                    "m-4 inline-block",
                    {
                      "bg-amber-500/50": colorKeys.includes("rentBkHistory")
                    }
                  )}
                >

                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Miete <span className="ml-1 font-bold text-green-500">Gesamtbelastung</span></>}
                    name="rentBkHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}
          </div>
        )
        : null}

      {/* --- buyable --- */}
      <CheckboxField
        label="Kaufpreise vorhanden"
        name="buyable"
        className={cn(
          "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
          {
            "bg-amber-500": colorKeys.includes("buyable")
          }
        )}
        {...{
          errors,
          register
        }}
      />

      {buyable
        ? (
          <div className="m-4 bg-white/50">
            <NumberField
              label="Kaufpreis Eigennutzer"
              name="offerPriceNormal"
              className={cn(
                "col-span-3",
                {
                  "bg-amber-500": colorKeys.includes("offerPriceNormal")
                }
              )}
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showOfferPriceNormalHistory"
              className={cn(
                "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
                {
                  "bg-amber-500": colorKeys.includes("showOfferPriceNormalHistory")
                }
              )}
              {...{
                errors,
                register
              }}
            />

            {showOfferPriceNormalHistory
              ? (
                <div
                  className={cn(
                    "m-4 inline-block",
                    {
                      "bg-amber-500/50": colorKeys.includes("offerPriceNormalHistory")
                    }
                  )}
                >

                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Kaufpreis Eigennutzer</>}
                    name="offerPriceNormalHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              label={<>Kaufpreis Anleger <span className="ml-0.5 font-bold text-indigo-600">exkl. USt</span></>}
              name="offerPriceInvestor"
              className={cn(
                "col-span-3",
                {
                  "bg-amber-500": colorKeys.includes("offerPriceInvestor")
                }
              )}
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showOfferPriceInvestorHistory"
              className={cn(
                "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
                {
                  "bg-amber-500": colorKeys.includes("showOfferPriceInvestorHistory")
                }
              )}
              {...{
                errors,
                register
              }}
            />

            {showOfferPriceInvestorHistory
              ? (
                <div
                  className={cn(
                    "m-4 inline-block",
                    {
                      "bg-amber-500/50": colorKeys.includes("offerPriceInvestorHistory")
                    }
                  )}
                >

                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Kaufpreis Anleger <span className="ml-0.5 font-bold text-indigo-600">exkl. USt</span></>}
                    name="offerPriceInvestorHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },

                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              label={<>Kaufpreis Anleger <span className="ml-0.5 font-bold text-green-500">inkl. USt</span></>}
              name="offerPriceBruttoInvestor"
              className={cn(
                "col-span-3",
                {
                  "bg-amber-500": colorKeys.includes("offerPriceBruttoInvestor")
                }
              )}
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showOfferPriceBruttoInvestorHistory"
              className={cn(
                "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
                {
                  "bg-amber-500": colorKeys.includes("showOfferPriceBruttoInvestorHistory")
                }
              )}
              {...{
                errors,
                register
              }}
            />

            {showOfferPriceBruttoInvestorHistory
              ? (
                <div
                  className={cn(
                    "m-4 inline-block",
                    {
                      "bg-amber-500/50": colorKeys.includes("offerPriceBruttoInvestorHistory")
                    }
                  )}
                >

                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Kaufpreis Anleger <span className="ml-0.5 font-bold text-green-500">inkl. USt</span></>}
                    name="offerPriceBruttoInvestorHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}
          </div>
        )
        : null}

      {/* --- data --- */}

      <NumberField
        area
        label="Fläche"
        name="offerArea"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerArea")
          }
        )}
        {...{
          control,
          errors,
          register
        }}
      />

      <NumberField
        int
        label="Zimmer"
        name="offerRoomCount"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerRoomCount")
          }
        )}
        {...{
          control,
          errors,
          register
        }}
      />

      <MultiField
        id="offerLoggiaCount"
        label="Loggia Anzahl / Fläche"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerLoggiaCount") || colorKeys.includes("offerLoggiaArea")
          }
        )}
      >
        <NumberInput
          int
          name="offerLoggiaCount"
          placeholder="Loggia Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerLoggiaArea"
          placeholder="Loggia Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField
        id="offerBalkonCount"
        label="Balkon Anzahl / Fläche"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerBalkonCount") || colorKeys.includes("offerBalkonArea")
          }
        )}
      >
        <NumberInput
          int
          name="offerBalkonCount"
          placeholder="Balkon Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerBalkonArea"
          placeholder="Balkon Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField
        id="offerGartenCount"
        label="Garten Anzahl / Fläche"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerGartenCount") || colorKeys.includes("offerGartenArea")
          }
        )}
      >
        <NumberInput
          int
          name="offerGartenCount"
          placeholder="Garten Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerGartenArea"
          placeholder="Garten Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField
        id="offerTerrasseCount"
        label="Terrasse Anzahl / Fläche"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerTerrasseCount") || colorKeys.includes("offerTerrasseArea")
          }
        )}
      >
        <NumberInput
          int
          name="offerTerrasseCount"
          placeholder="Terrasse Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerTerrasseArea"
          placeholder="Terrasse Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField
        id="offerKellerCount"
        label="Keller Anzahl / Fläche"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerKellerCount") || colorKeys.includes("offerKellerArea")
          }
        )}
      >
        <NumberInput
          int
          name="offerKellerCount"
          placeholder="Keller Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerKellerArea"
          placeholder="Keller Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <AreaField
        label="Sonstige Bemerkungen"
        name="offerNotice"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("offerNotice")
          }
        )}
        {...{
          control,
          errors,
          register
        }}
      />

      <AreaField
        label="Interne Notizen"
        name="internalOfferNotes"
        className={cn(
          "",
          {
            "bg-amber-500": colorKeys.includes("internalOfferNotes")
          }
        )}
        {...{
          control,
          errors,
          register
        }}
      />

    </div>
  );
};

export default OfferForm;
