/**
 * Default initial values for project certificate form
 */
const initialValues = {
  assigned_points: null,
  issuer: null,
  issuer_certificate: null,
  notes: null
};

export default initialValues;
