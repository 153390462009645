/* eslint-disable max-lines-per-function */
import {
  useEffect,
  useState
} from "react";
import { useForm } from "react-hook-form";

import API from "~/src/modules/api.js";
import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import AreaField from "~/src/ui/forms/area-field/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.new.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import handler from "../_common/handler.js";

import { initialValues } from "./_common/_exports.js";
import { generateEditValues, schema } from "./project-certificate-slide/_exports.js";

/**
 * @import { ProjectCertificate } from "~/src/_common/project-certificate.doc.js";
 * @import { CertificateIssuer } from "~/src/_common/certificate-issuer.doc.js";
 */

const {
  handleCreate,
  handleUpdate
} = handler;

/**
 * Loads certificate issuer options from the API
 *
 * @param {string} name - Search term for issuers
 * @returns {Promise<readonly CertificateIssuer[]>} List of matching issuers
 * @example
 */
const loadCertificateIssuerOptions = async (name) => {
  const parameters = new URLSearchParams({
    name,
    searchable: "true"
  });

  const url = `/certificate-issuers?${parameters}`;

  const { data: { payload: { issuers } } } = await API.get(url);

  return issuers;
};

/**
 * Project certificate edit/create slide component
 *
 * @param {object} props - Component props
 * @param {Function} props.hide - Function to hide the slide
 * @param {ProjectCertificate} props.projectCertificate - Certificate data for editing
 * @param {number} props.projectId - ID of the project
 * @param {boolean} props.visible - Visibility state of the slide
 * @returns {JSX.Element} The rendered slide component
 * @example
 */
const ProjectCertificateSlide = ({
  hide,
  projectCertificate,
  projectId,
  visible
}) => {
  const { mutate } = useProject(projectId);

  const [isLoading, setIsLoading] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    control,
    formState: {
      errors,
      isDirty
    },
    handleSubmit,
    register,
    reset,
    resetField,
    setFocus,
    setValue,
    watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema),
    shouldFocusError: false
  });

  const issuer = watch("issuer");

  const showCertificatesField = issuer && issuer.has_certificates;
  const showPointsField = issuer && issuer.has_points;

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const onSubmit = (data) => {
    if (projectCertificate) {
      handleUpdate({
        id: projectCertificate.id,
        data,
        hide: handleClose,
        mutate,
        setIsLoading,
        showError,
        showSuccess
      });
    }
    else {
      handleCreate({
        data,
        hide: handleClose,
        mutate,
        projectId,
        setIsLoading,
        showError,
        showSuccess
      });
    }
  };

  useEffect(
    () => {
      reset(
        projectCertificate
          ? generateEditValues(projectCertificate)
          : initialValues
      );
    },
    [
      projectCertificate,
      projectId,
      visible
    ]
  );

  useEffect(() => {
    if (isDirty) {
      setValue("issuer_certificate", null);
    }
  }, [issuer]);

  useEffect(() => {
    if (!showPointsField) {
      setValue("assigned_points", null);
    }
  }, [showPointsField]);

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form
        autoComplete="off"
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex-1">
          <SlideFormHeader closeDisabled={isLoading} handleHide={handleClose} title={`Gebäudezertifikat ${projectCertificate ? "bearbeiten" : "erstellen"}`} />

          <ComboField
            label="Aussteller"
            name="issuer"
            options={loadCertificateIssuerOptions}
            {... {
              control,
              resetField,
              setFocus,
              setValue,
              watch
            }}
            className="col-span-6"
          />

          {
            showCertificatesField
              ? (
                <ComboField
                  label="Zertifikat"
                  name="issuer_certificate"
                  options={issuer.certificates}
                  {... {
                    control,
                    resetField,
                    setFocus,
                    setValue,
                    watch
                  }}
                  className="col-span-6"
                />
              )
              : null
          }

          {
            showPointsField
              ? (
                <NumberField
                  int
                  label="Punkte"
                  name="assigned_points"
                  {...{
                    control,
                    errors,
                    register
                  }}
                  className="col-span-6"
                />
              )
              : null
          }

          <AreaField
            resizable
            label="Sonstige Bemerkungen"
            name="notes"
            {...{
              control,
              errors,
              register
            }}
          />
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={isLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default ProjectCertificateSlide;
