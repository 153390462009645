import CloseIcon from "~/src/ui/icons/close-icon.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.closeDisabled - The root object
 * @param props0.handleHide - The root object
 * @param props0.title - The root object
 * @example
 */
const SlideFormHeader = ({
  closeDisabled = false,
  handleHide,
  title
}) => (
  <div className="bg-gray-800 px-4 py-6 sm:px-6">
    <div className="flex items-start justify-between space-x-3">
      <div className="space-y-1">
        <h2 className="text-lg font-medium text-white">
          {title}
        </h2>
      </div>

      <div className="flex h-7 items-center">
        <button className="rounded-md text-white hover:text-gray-100 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 disabled:opacity-50" disabled={closeDisabled} onClick={handleHide} type="button">
          <CloseIcon />
        </button>
      </div>
    </div>
  </div>
);

export default SlideFormHeader;
