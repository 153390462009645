const initialValues = {
  max_points: null,
  certificates: [
    {
      id: null,
      name: "",
      rank: null
    }
  ],
  has_certificates: false,
  has_points: false,
  internal_notes: "",
  name: "",
  searchable: true
};

export default initialValues;
