import { PencilIcon } from "lucide-react";
import { useState } from "react";

import { roleLabels } from "~/src/modules/labels.jsx";

import useUsers from "~/src/hooks/use-users.js";

import SortUserButton from "~/src/ui/buttons/sort-user-button/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @param props0 - The root object
 * @param props0.showDeleteModal - The root object
 * @param props0.showEditModal - The root object
 * @example
 */
const UserList = ({ showDeleteModal, showEditModal }) => {
  const { isLoading, users } = useUsers();
  const [sortReverse, setSortReverse] = useState(false);

  if (isLoading) {
    return <Skeleton />;
  }

  const orderedUsers = sortReverse ? [...users].reverse() : users;

  return (
    <div className="mt-12 grid grid-cols-1 overflow-hidden rounded-lg border-2 border-gray-200">

      <div className="col-span-1 hidden grid-cols-6 border-b-2 border-gray-200 bg-gray-100 text-gray-500 md:grid">

        <div className="col-span-2 px-4 py-3">
          <SortUserButton handleClick={() => setSortReverse(!sortReverse)} />
        </div>

        <div className="col-span-2 flex items-center px-4 py-3 text-xs font-bold uppercase tracking-wider">
          <p>Name</p>
        </div>

        <div className="col-span-2 flex items-center px-4 py-3 text-xs font-bold uppercase tracking-wider">
          <p>Rolle</p>
        </div>

      </div>

      <div className="col-span-1 grid grid-cols-1 divide-y-2 divide-gray-200">

        {orderedUsers?.map((user) => (
          <div className="col-span-1 grid md:grid-cols-6" key={`users_${user.id}`}>

            <div className="col-span-2 flex items-center whitespace-nowrap p-4 text-sm text-gray-500">
              <p className="truncate font-medium text-gray-500">
                {user.email}
              </p>
            </div>

            <div className="col-span-2 flex items-center whitespace-nowrap p-4 text-sm text-gray-500">
              {user.username}
            </div>

            <div className="col-span-1 flex items-center whitespace-nowrap p-4 text-sm text-gray-500">
              {user.role ? roleLabels[user.role] : "deaktiviert"}
            </div>

            <div className="flex items-center space-x-4 p-4">
              <button className="focus:outline-hidden" onClick={() => showEditModal(user)} type="button">
                <PencilIcon className="size-6 text-gray-500 hover:text-gray-600" />
              </button>

              {/* <button type="button" onClick={() => showDeleteModal(user)} className="focus:outline-hidden">
                <DeleteIcon className="h-6 w-6 text-gray-500 hover:text-gray-600" />
              </button> */}
            </div>

          </div>
        ))}

      </div>

    </div>
  );
};

export default UserList;
