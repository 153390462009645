import { Transition } from "@headlessui/react";
import {
  EyeOffIcon, LoaderCircleIcon, PencilIcon
} from "lucide-react";

import useAuth from "~/src/hooks/use-auth.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

const CertificateIssuersList = ({
  isLoading,
  issuers = [],
  openIssuerModal
}) => {
  const { isAdmin } = useAuth();

  return (
    <ul className="relative flex w-full flex-col">
      <li className="w-full rounded-t-sm border-2 border-gray-200 bg-gray-100 p-3 text-xs font-bold uppercase tracking-wider text-gray-500">
        <span>Name</span>
      </li>

      {
        issuers.map((issuer) => {
          const {
            id,
            name,
            searchable
          } = issuer;

          return (
            <li
              className="flex w-full items-center justify-between border-2 border-t-0 border-gray-200 p-4 text-sm font-medium text-gray-500 last:rounded-b-sm"
              key={id}
            >
              <div className="flex gap-2">
                {
                  searchable
                    ? null
                    : (<EyeOffIcon className="size-5 text-red-500" />)
                }

                <span
                  className="cursor-pointer underline hover:text-gray-600 hover:no-underline"
                  onClick={() => {
                    openIssuerModal({
                      issuer,
                      mode: "view"
                    });
                  }}
                >
                  {name}
                </span>
              </div>

              {
                isAdmin
                  ? (
                    <button
                      className="hover:text-gray-600"
                      type="button"
                      onClick={() => {
                        openIssuerModal({
                          issuer,
                          mode: "edit"
                        });
                      }}
                    >
                      <PencilIcon className="size-5 " />
                    </button>
                  )
                  : null
              }

            </li>
          );
        })
      }

      {
        issuers.length === 0 && (
          <li
            className="h-13.5 flex w-full items-center justify-center border-2 border-t-0 border-gray-200 p-4 text-sm font-medium text-gray-400 last:rounded-b-sm"
          >
            <span>Keine Ergebnisse</span>

          </li>
        )
      }

      <Transition
        show={isLoading}
      >
        <div className="backdrop-blur-xs data-[leave]:duration-50 absolute bottom-0 flex h-[calc(100%-2.5rem)] w-full items-center justify-center bg-white/10 transition data-[closed]:opacity-0 data-[closed]:backdrop-blur-none data-[enter]:delay-300 data-[enter]:duration-300">
          <LoaderCircleIcon className="size-8 animate-spin" />
        </div>
      </Transition>
    </ul>
  );
};

export default CertificateIssuersList;
