/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.name - The root object
 * @param props0.onBlur - The root object
 * @param props0.onChange - The root object
 * @param props0.value - The root object
 * @example
 */
const BulkEditCheckbox = ({
  id,
  name,
  onBlur,
  onChange,
  value
}) => (
  <input
    checked={value}
    className="size-5 cursor-pointer rounded-sm border-gray-300 text-gray-600 focus:ring-0 focus:ring-transparent"
    id={id}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    type="checkbox"
  />
);

export default BulkEditCheckbox;
