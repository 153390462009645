import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/research-filter",

  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: ({ project_research_filter, unit_research_filter }) => ({
    project: {
      project_research_filter: project_research_filter || null,
      unit_research_filter: unit_research_filter || null
    }
  })
});

export {
  handleUpdate
};
