import { useState } from "react";

import CompaniesSearchForm from "~/src/features/companies-search-form/index.jsx";
import CompanyList from "~/src/features/company-list/index.jsx";
import CompanyDetailsModal from "~/src/features/modals/company-details-modal/index.jsx";
import EditCompanyModal from "~/src/features/modals/edit-company-modal/index.jsx";
import NewCompanyModal from "~/src/features/modals/new-company-modal/index.jsx";
import Pagination from "~/src/features/pagination/index.jsx";

import useCompanies from "~/src/hooks/use-companies.js";
import useModal from "~/src/hooks/use-modal.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";
import PageTitle from "~/src/ui/titles/page-title.jsx";

/**
 *
 * @example
 */
const CompaniesPage = () => {
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const {
    companies, isError, isLoading, mutate, pagination
  } = useCompanies({
    name,
    page
  });

  const {
    hide: hideNewModal, show: showNewModal, visible: newModalVisible
  } = useModal();
  const {
    data: detailModalData, hide: hideDetailsModal, show: showDetailsModal, visible: detailsModalVisible
  } = useModal();
  const {
    data: editModalData, hide: hideEditModal, show: showEditModal, visible: editModalVisible
  } = useModal();

  if (isError) {
    return null;
  }

  return (
    <Page title="Unternehmen">
      <NewCompanyModal hide={hideNewModal} mutateCompanies={mutate} visible={newModalVisible} />

      <CompanyDetailsModal company={detailModalData} hide={hideDetailsModal} visible={detailsModalVisible} />

      <EditCompanyModal company={editModalData} hide={hideEditModal} mutateCompanies={mutate} visible={editModalVisible} />

      <PageHeader>
        <PageTitle> Unternehmen </PageTitle>

        <button className="mt-6 block text-sm underline focus:outline-hidden" onClick={showNewModal} type="button">Neues Unternehmen hinzufügen</button>
      </PageHeader>

      <MainContainer>

        <CompaniesSearchForm setName={setName} setPage={setPage} />

        <Pagination labels={["Unternehmen", "Unternehmen"]} page={page} pagination={pagination} setPage={setPage} />

        <CompanyList companies={companies} isLoading={isLoading} showDetailsModal={showDetailsModal} showEditModal={showEditModal} />

        <Pagination labels={["Unternehmen", "Unternehmen"]} page={page} pagination={pagination} setPage={setPage} />

      </MainContainer>

    </Page>
  );
};

export default CompaniesPage;
