export const initialValues = {
  project_research_filter: "",
  unit_research_filter: ""
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    const { project_research_filter, unit_research_filter } = project;

    return {
      project_research_filter: project_research_filter || "",
      unit_research_filter: unit_research_filter || ""
    };
  }

  return initialValues;
};

export { generateEditValues };
