import Handler from "~/src/modules/handler.js";

const handler = new Handler({
  endpoint: "/certificate-issuers/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (
    responseData,
    {
      hide,
      mutate,
      showSuccess
    }
  ) => {
    await mutate();
    showSuccess();
    hide();
  },
  transformCreateData: ({
    max_points,
    certificates,
    has_certificates,
    has_points,
    ...rest
  }) => ({
    ...rest,
    max_points: has_points
      ? max_points
      : null,
    certificates: has_certificates
      ? certificates
        .filter(({ name, rank }) => typeof name === "string" && typeof rank === "number")
      : [],
    has_certificates,
    has_points
  })
});

export default handler;
