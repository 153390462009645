import cn from "classnames";
import de from "date-fns/locale/de";
import DatePicker, { registerLocale } from "react-datepicker";

registerLocale("de", de);

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.minDate - The root object
 * @param props0.maxDate - The root object
 * @param props0.autoFocus - The root object
 * @param props0.error - The root object
 * @param props0.format - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.name - The root object
 * @param props0.onBlur - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.touched - The root object
 * @param props0.value - The root object
 * @example
 */
const DateInput = ({
  id,
  minDate = new Date("1000-01-01"),
  maxDate = getTodayInHoundredYears(),
  autoFocus = false,
  error,
  format,
  isSubmitting,
  name,
  onBlur,
  onChange,
  placeholder,
  touched,
  value
}) => {
  const touchedAndNotEmpty = (Boolean(touched) === true) && value !== "";
  const showError = error && (isSubmitting || touchedAndNotEmpty);

  /**
   *
   * @param date
   * @example
   */
  function handleChange(date) {
    const event = {
      target: {
        name,
        type: "custom-select",
        value: date
      }
    };

    onChange(event);
  }

  return (
    <>
      <div className="relative">
        <DatePicker
          autoFocus={autoFocus}
          dateFormat={dateFormat(format)}
          dropdownMode="select"
          id={id}
          locale="de"
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          peekNextMonth={!showYearSelects(format)}
          placeholderText={placeholder}
          selected={value}
          showMonthDropdown={!showYearSelects(format)}
          showYearDropdown={!showYearSelects(format)}
          showYearPicker={showYearSelects(format)}
          className={cn("block w-full border rounded-xs shadow-xs bg-white h-10 px-3 focus:outline-hidden sm:text-sm placeholder-gray-300", {
            "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
            "border-red-500": showError
          })}
        />

        {showError
          ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
              </svg>
            </div>
          )
          : null}
      </div>

      {showError ? <p className="mt-2 text-sm text-red-600">{error.message}</p> : null}
    </>
  );
};

export default DateInput;

/**
 *
 * @param format
 * @example
 */
function dateFormat(format) {
  if (format === "year") {
    return "yyyy";
  }

  return "dd.MM.yyyy";
}

/**
 *
 * @param format
 * @example
 */
function showYearSelects(format) {
  if (format === "year") {
    return true;
  }

  return false;
}

/**
 *
 * @example
 */
function getTodayInHoundredYears() {
  const date = new Date();

  date.setHours(23, 59, 59, 999);
  date.setFullYear(date.getFullYear() + 100);

  return date;
}
