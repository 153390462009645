import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useForm from "~/src/hooks/use-form.js";

import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import LockIcon from "~/src/ui/icons/lock-icon/index.jsx";

import {
  handleSubmit,
  initialValues,
  schema
} from "./login/_exports.js";

/**
 *
 * @example
 */
const LoginPage = () => {
  const history = useHistory();
  const { mutate, user } = useAuth();

  const form = useForm({
    initialValues,
    onSubmit,
    schema
  });
  const [error, setError] = useState(false);

  /**
   *
   * @example
   */
  function onSubmit() {
    setError(false);
    handleSubmit(form, handleSuccess, handleError);
  }

  /**
   *
   * @param res
   * @example
   */
  function handleSuccess(res) {
    mutate();
  }

  /**
   *
   * @param error
   * @example
   */
  function handleError(error) {
    if (error) {
      setError(true);
    }
  }

  useEffect(() => {
    if (user) {
      history.replace("/");
    }
  }, [user]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <form autoComplete="off" className="mt-8 space-y-6" onSubmit={form.handleSubmit}>

          <div className="space-y-2 rounded-md shadow-xs">
            {error ? <p className="mb-4 font-medium text-red-500">Email oder Passwort sind nicht korrekt.</p> : null}

            <div>
              <InlineInput
                autoFocus
                id="email"
                isSubmitting={form.isSubmitting}
                name="email"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                placeholder="Email Adresse"
                touched={form.touched.email}
                value={form.values.email}
              />
            </div>

            <div className="">
              <InlineInput
                id="password"
                isSubmitting={form.isSubmitting}
                name="password"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                placeholder="Passwort"
                touched={form.touched.password}
                type="password"
                value={form.values.password}
              />
            </div>
          </div>

          <div>
            <button className="group relative flex w-full justify-center rounded-md border border-transparent bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-gray-800 focus:outline-hidden focus:ring-2 focus:ring-gray-500 focus:ring-offset-2" disabled={form.isLoading} type="submit">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">

                {form.isLoading
                  ? (
                    <svg className="-ml-1 mr-3 size-5 animate-spin text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />

                      <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
                    </svg>
                  )
                  : null}

                {form.isLoading ? <LockIcon /> : null}

              </span>
              Anmelden
            </button>
          </div>
        </form>
      </div>
    </div>

  );
};

export default LoginPage;
