import { useEffect, useState } from "react";

import SearchIcon from "~/src/ui/icons/search-icon/index.jsx";
import CitySelect from "~/src/ui/selects/city-select/index.jsx";
import StateSelect from "~/src/ui/selects/state-select/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.cityId - The root object
 * @param props0.handleUpdate - The root object
 * @param props0.name - The root object
 * @param props0.resetFilter - The root object
 * @param props0.state - The root object
 * @param props0.street - The root object
 * @example
 */
const ProjectsSearchForm = ({
  cityId = "", handleUpdate, name = "", resetFilter, state = "", street = ""
}) => {
  const [nameChange, setNameChange] = useState(name);
  const [streetChange, setStreetChange] = useState(street);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleUpdate("nameChange", nameChange), 500);

    return () => clearTimeout(timeOutId);
  }, [nameChange]);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleUpdate("streetChange", streetChange), 500);

    return () => clearTimeout(timeOutId);
  }, [streetChange]);

  useEffect(() => {
    if (street !== streetChange) {
      setStreetChange("");
    }

    if (name !== nameChange) {
      setNameChange("");
    }
  }, [name, street]);

  const handleNameChange = (event) => {
    setNameChange(event.target.value);
  };
  const handleStreetChange = (event) => {
    setStreetChange(event.target.value);
  };
  const handleCityId = (event) => {
    handleUpdate("cityIdChange", event.target.value);
  };
  const handleState = (event) => {
    handleUpdate("stateChange", event.target.value.value);
  };

  return (
    <form>
      <div className="mt-12 md:flex">
        <div className="w-full grid-cols-8 gap-x-1 space-y-2 md:grid md:space-y-0">

          <div className="relative col-span-2 w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon styles="h-4 w-4 text-gray-400" />
            </div>

            <input
              autoComplete="off"
              className="block w-full rounded-sm border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder:text-gray-500 focus:border-gray-500 focus:text-gray-900 focus:outline-hidden focus:ring-1 focus:ring-gray-500 focus:placeholder:text-gray-400 sm:text-sm"
              name="name"
              onChange={handleNameChange}
              placeholder="Projektname"
              type="search"
              value={nameChange}
            />
          </div>

          <div className="relative col-span-2 w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon styles="h-4 w-4 text-gray-400" />
            </div>

            <input
              autoComplete="off"
              className="block w-full rounded-sm border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder:text-gray-500 focus:border-gray-500 focus:text-gray-900 focus:outline-hidden focus:ring-1 focus:ring-gray-500 focus:placeholder:text-gray-400 sm:text-sm"
              name="street"
              onChange={handleStreetChange}
              placeholder="Straße"
              type="search"
              value={streetChange}
            />
          </div>

          <div className="relative col-span-2 w-full">
            <CitySelect
              cityId={cityId}
              onChange={handleCityId}
              placeholder="Postleitzahl"
            />
          </div>

          <div className="relative col-span-2 w-full">
            <StateSelect
              defaultValue={state}
              onChange={handleState}
            />
          </div>
        </div>

        <div className="mt-6 md:ml-2 md:mt-0">
          <button className="inline-flex h-10 w-full items-center justify-center rounded-sm border border-gray-300 bg-red-800 px-12 font-medium text-white hover:bg-red-900 focus:outline-hidden md:h-full" onClick={resetFilter} type="reset">
            Zurücksetzen
          </button>
        </div>

      </div>
    </form>
  );
};

export default ProjectsSearchForm;
