import { useParams } from "react-router-dom";

import DeleteModal from "~/src/ui/modals/delete-modal.jsx";

import { handler } from "./_common/_exports.js";
import { ProjectCertificateSlide } from "./project-certificates-portals/_exports.js";

const {
  handleDelete
} = handler;

/**
 * Portal container for project certificate modals and slides
 *
 * @param {object} props - Component props
 * @param {object} props.deleteData - Data for delete operation
 * @param {boolean} props.deleteVisible - Delete modal visibility
 * @param {Function} props.hideDelete - Hide delete modal function
 * @param {Function} props.hideSlide - Hide slide function
 * @param {Function} props.mutate - Data mutation function
 * @param {object} props.slideData - Data for slide
 * @param {boolean} props.slideVisible - Slide visibility
 * @example
 */
const ProjectCertificatesPortals = ({
  deleteData,
  deleteVisible,
  hideDelete,
  hideSlide,
  mutate,
  slideData,
  slideVisible
}) => {
  const { id } = useParams();

  return (
    <>
      <ProjectCertificateSlide
        hide={hideSlide}
        projectCertificate={slideData}
        projectId={id}
        visible={slideVisible}
      />

      <DeleteModal
        handleDelete={handleDelete}
        hide={hideDelete}
        id={deleteData?.id}
        mutate={mutate}
        show={deleteVisible}
      />
    </>

  );
};

export default ProjectCertificatesPortals;
