import { useState } from "react";

import useAuth from "~/src/hooks/use-auth.js";
import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import SlideFormFooter from "~/src/ui/footers/slide-form-footer/index.new.jsx";
import DateInput from "~/src/ui/forms/date-input/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import CloseIcon from "~/src/ui/icons/close-icon.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./handlers.js";
import { generateEditValues, schema } from "./schema.js";

const ProjectReviewSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError, showSuccess } = useNotification();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = generateEditValues(project);

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    setIsLoading(false);
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      setIsLoading(true);
      handleUpdate({
        id: project.id,
        data: form.values,
        form,
        hide: () => {
          handleHide(form);
        },
        mutate,
        project,
        setIsLoading,
        showError,
        showSuccess,
        userId: user.id
      });
    },
    schema
  });

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form
        autoComplete="off"
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={form.handleSubmit}
      >
        <div className="flex-1">
          <div className="bg-gray-800 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2 className="text-lg font-medium text-white">
                  Letzte Kontrolle bearbeiten
                </h2>
              </div>

              <div className="flex h-7 items-center">
                <button
                  className="rounded-md text-white hover:text-gray-100 focus:outline-hidden focus:ring-2 focus:ring-indigo-500"
                  type="button"
                  onClick={() => {
                    handleHide(form);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="last_review_date">
                  Letzte Projektkontrolle
                </label>
              </div>

              <div className="sm:col-span-2">
                <DateInput
                  error={form.errors.last_review_date}
                  id="last_review_date"
                  isSubmitting={form.isSubmitting}
                  name="last_review_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.last_review_date}
                  value={form.values.last_review_date ? new Date(form.values.last_review_date) : ""}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="sm:col-span-1">
                <InlineCheckbox
                  error={form.errors.last_review_should_filter}
                  id="last_review_should_filter"
                  isSubmitting={form.isSubmitting}
                  label="Projektkontrolle aktiv"
                  name="last_review_should_filter"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.last_review_should_filter}
                  value={form.values.last_review_should_filter}
                />
              </div>
            </div>
          </div>

          <div className="mt-24 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="last_unit_offer_review_date">
                  Letzte Kontrolle (Angebote)
                </label>
              </div>

              <div className="sm:col-span-2">
                <DateInput
                  error={form.errors.last_unit_offer_review_date}
                  id="last_unit_offer_review_date"
                  isSubmitting={form.isSubmitting}
                  maxDate={new Date()}
                  name="last_unit_offer_review_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.last_unit_offer_review_date}
                  value={form.values.last_unit_offer_review_date ? new Date(form.values.last_unit_offer_review_date) : ""}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="last_unit_offer_research_date">
                  Letzte Erfassung (Angebote)
                </label>
              </div>

              <div className="sm:col-span-2">
                <DateInput
                  error={form.errors.last_unit_offer_research_date}
                  id="last_unit_offer_research_date"
                  isSubmitting={form.isSubmitting}
                  maxDate={new Date()}
                  name="last_unit_offer_research_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.last_unit_offer_research_date}
                  value={form.values.last_unit_offer_research_date ? new Date(form.values.last_unit_offer_research_date) : ""}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="last_unit_sale_research_date">
                  Letzte KV-Erfassung/Kontrolle
                </label>
              </div>

              <div className="sm:col-span-2">
                <DateInput
                  error={form.errors.last_unit_sale_research_date}
                  id="last_unit_sale_research_date"
                  isSubmitting={form.isSubmitting}
                  maxDate={new Date()}
                  name="last_unit_sale_research_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.last_unit_sale_research_date}
                  value={form.values.last_unit_sale_research_date ? new Date(form.values.last_unit_sale_research_date) : ""}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="sm:col-span-1">
                <InlineCheckbox
                  error={form.errors.last_unit_review_should_filter}
                  id="last_unit_review_should_filter"
                  isSubmitting={form.isSubmitting}
                  label="Objektkontrolle aktiv"
                  name="last_unit_review_should_filter"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.last_unit_review_should_filter}
                  value={form.values.last_unit_review_should_filter}
                />
              </div>
            </div>
          </div>
        </div>

        <SlideFormFooter
          isLoading={isLoading}
          handleHide={() => {
            handleHide(form);
          }}
        />
      </form>
    </SlideOver>
  );
};

export default ProjectReviewSlide;
