import Joi from "joi";

const initialValues = {
  unit_notes: ""
};

const schema = Joi.object({
  unit_notes: Joi.string().optional()
});

export { initialValues };

export default schema;
