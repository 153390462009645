import cn from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import API from "~/src/modules/api.js";
import { formatDate } from "~/src/modules/formatters.js";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useLastJob from "~/src/hooks/use-last-job.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";
import useSyncStatus from "~/src/hooks/use-sync-status.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";

import { handlePublishToggle } from "./handlers.js";

const poll = async (function_) => {
  let timeout = 1_000;
  let shouldStop = false;

  while (!shouldStop) {
    await new Promise((resolve) => {
      setTimeout(() => {
        timeout *= 2;
        resolve();
      }, timeout);
    });

    shouldStop = await function_() && timeout <= 2 ** 20;
  }
};

/**
 *
 * @example
 */
const ProjectSettings = () => {
  const { id } = useParams();
  const { mutate, project } = useProject(id);

  const [isSubmitting, setIsSubmitting] = useState(project.synchronizing);
  const [isSynchronized, setIsSynchronized] = useState(!project.unpublished_changes);

  const { showError } = useNotification();
  const { job, mutate: mutateJob } = useLastJob(`synchronize-project-${id}`);
  const { isMod, user } = useAuth();
  const { editPossible } = useEdit({
    project,
    user
  });

  const { syncStatus } = useSyncStatus();

  /**
   *
   * @example
   */
  async function publishUpdates() {
    try {
      setIsSubmitting(true);
      const response = await API.post(`/projects/${project.id}/publish-updates`);

      if (response) {
        const synchronizing = true;
        const { unpublished_changes } = project;

        // TODO[2025-02-01]: FIX POLLING (keeps requesting even if unmounted -> cancel requests in swr)
        // await poll(async () => {
        //   ({ payload: { project: { synchronizing, unpublished_changes } } } = await mutate());

        //   await mutateJob();

        //   return !synchronizing;
        // });

        await setTimeout(() => {
          setIsSubmitting(false);
          setIsSynchronized(!unpublished_changes);
        }, 5_000);
      }
      else {
        throw new Error();
      }
    }
    catch {
      showError();
      setIsSubmitting(false);
    }
  }

  // useEffect(async () => {
  //   const { synchronizing } = project;
  //   const { unpublished_changes } = project;

  //   // TODO[2025-02-01]: FIX POLLING (keeps requesting even if unmounted -> cancel requests in swr)
  //   // await poll(async () => {
  //   //   ({ payload: { project: { synchronizing, unpublished_changes } } } = await mutate());

  //   //   await mutateJob();

  //   //   return !synchronizing && unpublished_changes;
  //   // });

  //   // await setTimeout(() => {
  //   //   setIsSubmitting(false);
  //   //   setIsSynchronized(!unpublished_changes);
  //   // }, 10000);
  // }, []);

  useEffect(() => {
    setIsSynchronized(Boolean(!project?.unpublished_changes));
  }, [project?.published]);

  if (!syncStatus) {
    return (
      <div className="flex flex-col items-start gap-4">
        <span> Aktuell können keine Projecte synchronisiert werden!</span>

        <button
          className="shadow-xs focus:outline-hidden inline-flex items-center rounded-sm border border-transparent bg-gray-700 px-4 py-2 text-base font-medium text-white hover:bg-gray-800 focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
          onClick={() => {
            globalThis.location.reload();
          }}
        >
          Aktualisieren
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center gap-4">
        <SubmitButton
          disabled={!editPossible}
          isSubmitting={isSubmitting}
          onClick={publishUpdates}
          submitText="Wird synchronisiert"
          className={cn(
            "inline-flex items-center px-4 py-2 border border-transparent shadow-xs text-base font-medium rounded-sm focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 bg-gray-700 hover:bg-gray-800 text-white",
            {
              "cursor-not-allowed pointer-events-none": isSubmitting || !editPossible,
              "opacity-50 cursor-not-allowed": !editPossible
            }
          )}
        >
          <span>Änderungen synchronisieren</span>
        </SubmitButton>

        {
          (job && job?.end) && (
            <span className="text-sm text-gray-600">
              Letzte Synchroniserung:
              {" "}

              {formatDate({
                date: job.end,
                withTime: true
              })}

              {" "}
              |

              {" "}

              {dayjs(job.end).fromNow()}

              {" "}
              | Status:

              {" "}

              {(job.error) ? job.error : "erfolgreich"}
            </span>
          )
        }
      </div>

      <div className="mt-12 flex items-center">
        <span className="mr-3">
          <span className="text-sm font-medium text-gray-700">Projekt öffentlich anzeigen</span>
        </span>

        <button
          disabled={!editPossible}
          onClick={() => handlePublishToggle(project.id, project.published, mutate)}
          type="button"
          className={cn(
            "z-0 relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-hidden focus:ring-2 focus:ring-offset-2",
            {
              "bg-amber-200 focus:ring-amber-200": !project?.published,
              "bg-emerald-300 focus:ring-emerald-200": project?.published,
              "opacity-50 cursor-not-allowed pointer-events-none": !editPossible
            }
          )}
        >
          <span
            className={cn(
              "inline-block h-5 w-5 rounded-full bg-white shadow-sm transform ring-0 transition ease-in-out duration-200",
              {
                "translate-x-0": !project?.published,
                "translate-x-5": project?.published
              }
            )}
          />
        </button>
      </div>

      {/* <div className="mt-20">
        <button onClick={() => setShowModal(true)} type="button" className="inline-flex justify-center rounded-md border border-transparent shadow-xs px-2 py-1 bg-red-600 text-xs font-medium text-white hover:bg-red-700 focus:outline-hidden">
          Projekt löschen
        </button>
      </div> */}
    </>
  );
};

export default ProjectSettings;
