import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.enableError - The root object
 * @param props0.error - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.onBlur - The root object
 * @param props0.onChange - The root object
 * @param props0.value - The root object
 * @example
 */
const InlineCheckbox = ({
  id,
  enableError,
  error,
  label,
  name,
  onBlur,
  onChange,
  value
}) => {
  const showError = enableError && error && error.message && error.message.length > 0;

  return (
    <div className="relative flex h-full items-end">
      <div className="flex h-5 items-center">
        <input
          checked={value}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="checkbox"
          className={cn(
            "focus:ring-0 focus:ring-transparent h-4 w-4 text-indigo-600 rounded-sm ",
            {
              "border-gray-300! border!": !showError,
              "border-red-600! border-2!": showError
            }
          )}
        />
      </div>

      <div className="ml-3 text-sm">
        <label className="cursor-pointer font-medium text-gray-700" htmlFor={id}>{label}</label>
      </div>

      {showError
        ? (
          <p className="absolute top-12 col-span-3 mt-2 text-sm text-red-600">
            {error.message}
          </p>
        )
        : null}
    </div>
  );
};

export default InlineCheckbox;
