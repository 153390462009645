import { ChevronUpIcon } from "lucide-react";

/**
 *
 * @example
 */
const ScrollTopButton = () => {


  /**
   *
   * @example
   */
  function handleClick() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 120);
  }

  return (
    <button className="inline-flex items-center rounded-sm border border-transparent bg-amber-200 px-4 py-2 text-base font-medium text-amber-800 shadow-xs hover:bg-amber-300 focus:outline-hidden focus:ring-0" onClick={handleClick} type="button">
      <ChevronUpIcon className="size-5" />
    </button>
  );
};

export default ScrollTopButton;
