import Joi from "joi";

export const initialValues = {
  email: "",
  password: "",
  role: {
    label: "",
    value: ""
  },
  username: "",
  username_short: ""
};

export const schema = Joi.object({
  email: Joi.string().empty("").required(),
  password: Joi.string().min(4).max(40)
    .required(),
  role: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("").required()
  }),
  username: Joi.string().empty("").required(),
  username_short: Joi.string().empty("").min(4)
    .max(6)
    .required()
});
