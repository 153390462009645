import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.disabled - The root object
 * @param props0.error - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.name - The root object
 * @param props0.onBlur - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.touched - The root object
 * @param props0.value - The root object
 * @example
 */
const InputArea = ({
  id,
  autoFocus = false,
  disabled = false,
  error,
  isSubmitting,
  name,
  onBlur,
  onChange,
  placeholder,
  touched,
  value
}) => {
  const touchedAndNotEmpty = (Boolean(touched) === true) && value !== "";
  const showError = error && (isSubmitting || touchedAndNotEmpty);

  return (
    <>
      <div className="relative">
        <textarea
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          rows="3"
          value={value}
          className={cn("mt-1 resize-none shadow-xs block w-full focus:ring-gray-700 focus:border-gray-700 sm:text-sm border-gray-300 rounded-xs placeholder-gray-300", {
            "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
            "border-red-500 text-red-900": showError
          })}
        />

        {showError
          ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
              </svg>
            </div>
          )
          : null}
      </div>

      {showError ? <p className="mt-2 text-sm text-red-600">{error.message}</p> : null}
    </>
  );
};

export default InputArea;
