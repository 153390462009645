import {
  Dialog, Transition, TransitionChild
} from "@headlessui/react";
import { Fragment } from "react";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.hide - The root object
 * @param props0.show - The root object
 * @example
 */
const ModalContainer = ({
  children, hide = () => null, show = false
}) => (
  <Transition as={Fragment} show={show}>
    <Dialog static as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={hide} open={show}>
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span aria-hidden="true" className="hidden sm:inline-block sm:h-screen sm:align-middle">
          &#8203;
        </span>

        <TransitionChild
          as={Fragment}
          className="relative"
          enter="ease-out duration-300 relative"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 relative"
          enterTo="opacity-100 translate-y-0 sm:scale-100 relative"
          leave="ease-in duration-150 relative"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100 relative"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 relative"
        >
          {children}
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
);

export default ModalContainer;
