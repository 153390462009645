/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @example
 */
const ModalCancelButton = ({ hide }) => (
  <button className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-xs hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm" onClick={hide} type="button">
    Abbrechen
  </button>
);

export default ModalCancelButton;
