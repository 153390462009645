import useSWR from "swr";

import { fetcher } from "../modules/api.js";

/**
 * @import { SWRResponse } from "swr";
 * @import { HookResult } from "./_common/_exports.js";
 * @import {
 * CertificateIssuersQuery,
 * CertificateIssuersResponse
 * } from "./use-certificate-issuers/_exports.js";
 */

/**
 * Custom hook to fetch certificate issuers based on a query.
 *
 * @param {CertificateIssuersQuery} query - The query parameters to be used in the API request.
 * @returns {HookResult<CertificateIssuersResponse>} An object containing the fetch state and data.
 * @example
 * const {
 *  isError,
 *  isLoading,
 *  issuers,
 *  mutate,
 *  pagination
 * } = useCertificateIssuers({
 *  name,
 *  page
 * });
 */
const useCertificateIssuers = (query) => {
  const parameters = new URLSearchParams(query);

  const {
    data,
    error,
    isLoading,
    isValidating,
    mutate
  } = /** @type {SWRResponse<CertificateIssuersResponse, Error>} */ (
    useSWR(`/certificate-issuers?${parameters}`, fetcher, { keepPreviousData: true })
  );

  return {
    ...data?.payload,
    error,
    isLoading,
    isValidating,
    mutate
  };
};

export default useCertificateIssuers;
