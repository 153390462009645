import SignedImage from "~/src/ui/images/signed-image/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.document - The root object
 * @param props0.handleDelete - The root object
 * @param props0.handleEdit - The root object
 * @param props0.showEditActions - The root object
 * @example
 */
const ProjectDocumentListItem = ({
  document, handleDelete, handleEdit, showEditActions
}) => {
  if (!document) {
    return null;
  }

  return (
    <li className="relative">
      <div className="group block aspect-[10/7] w-full overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
        <SignedImage document={document} styles="group-hover:opacity-75 object-cover" />
      </div>

      <p className="mt-2 block truncate text-sm font-medium text-gray-900">{document.public_name}</p>

      <p className="truncate text-sm font-medium text-gray-700">Sichtbarkeit: {document.show_public ? "öffentlich" : "privat"}</p>

      {showEditActions
        ? (
          <div className="mt-1 text-sm font-medium text-gray-500">
            <button className="hover:text-gray-700 focus:outline-hidden" onClick={() => handleEdit(document)} type="button">bearbeiten</button>

            <button className="ml-4 hover:text-gray-700 focus:outline-hidden" onClick={() => handleDelete(document)} type="button">löschen</button>
          </div>
        )
        : null}
    </li>
  );
};

export default ProjectDocumentListItem;
