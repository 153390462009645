import cn from "classnames";
import { FileOutputIcon } from "lucide-react";
import {
  useCallback, useEffect, useState
} from "react";
import { FormProvider, useForm } from "react-hook-form";

import API from "~/src/modules/api.js";
import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import UnitDocumentItemSmall from "~/src/ui/project-unit/unit-document-item-small/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import {
  generateEditValues,
  initialValues
} from "./_common/_exports.js";
import OfferForm from "./forms/offer-form/index.jsx";
import SaleForm from "./forms/sale-form/index.jsx";
import { handleCreate, handleUpdate } from "./handlers.js";
import {
  generateSaleEditValues,
  schema,
  transformContractToUnit
} from "./unit-form-slide/_exports.js";

/**
 *
 * @param root0 - The root object
 * @param root0.buildingParts - The root object
 * @param root0.hide - The root object
 * @param root0.mutateUnits - The root object
 * @param root0.projectId - The root object
 * @param root0.unit - The root object
 * @param root0.visible - The root object
 * @example
 */
const EditUnitSlide = ({
  buildingParts,
  hide,
  mutateUnits,
  projectId,
  unit,
  visible
}) => {
  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(unit !== null && unit !== undefined);
  const [copiedKvId, setCopiedKvId] = useState(null);

  const { showError, showSuccess } = useNotification();

  const form = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  const {
    control,
    formState: {
      errors,
      isDirty
    },
    getValues,
    handleSubmit,
    register,
    reset,
    resetField,
    setFocus,
    setValue,
    watch
  } = form;

  const kvIdToCopyForm = useForm({
    defaultValues: {
      kvIdToCopy: ""
    }
  });

  const {
    control: kvIdToCopyFormControl,
    formState: {
      errors: kvIdToCopyErrors
    },
    handleSubmit: kvIdToCopyHandleSubmit,
    register: kvIdToCopyRegister,
    reset: kvIdToCopyReset,
    setError: kvIdToCopySetError,
    watch: kvIdToCopyWatch
  } = kvIdToCopyForm;

  const setEditValues = useCallback(
    async (currentUnit) => {
      const editValues = await generateEditValues(currentUnit);

      reset(editValues);

      setIsEdit(true);
    },
    [reset]
  );

  useEffect(() => {
    if (unit) {
      setEditValues(unit);
    }
  }, [unit]);

  const handleClose = () => {
    reset(initialValues);
    kvIdToCopyReset();
    hide();
    setCopiedKvId(null);
  };

  const onSubmit = (data) => {
    const emptyInputInCombobox = data.buildingPart === undefined;

    if (isDirty || emptyInputInCombobox || copiedKvId !== null) {
      if (unit) {
        handleUpdate({
          id: unit.id,
          data,
          handleClose,
          mutate: mutateUnits,
          setIsLoading,
          showError,
          showSuccess
        });
      }
      else {
        handleCreate({
          data,
          handleClose,
          mutate: mutateUnits,
          projectId,
          setIsLoading,
          showError,
          showSuccess
        });
      }
    }
    else {
      handleClose();
    }
  };

  const loadBuildingPartOptions = async (inputValue) => {
    const response = await API.get(`/projects/${projectId}/building-parts`);

    if (response) {
      return response.data.payload.building_parts
        .map((part) => ({
          id: part.id,
          label: <p><span className="inline-block w-16 px-1 font-bold text-yellow-500">{Number.parseFloat(part.sorting)}</span>Bauteil: {part.name}</p>,
          name: part.name
        }))
        .filter((part) => part.name.includes(inputValue));
    }

    console.error("Error loading building parts");
  };

  const loadUnitCategoryOptions = async (inputValue) => {
    const { data: { payload: { quantities } } } = await API.get(`/projects/${projectId}/quantities`);

    const quantityCategories = new Set(quantities.map(({ unit_category_id }) => unit_category_id));

    return API.get("/unit-categories")
      .then((res) => {
        let options = res.data.payload.unit_categories;

        options = options
          .filter((category) => quantityCategories.has(category.id))
          .map((category) => ({
            id: category.id,
            code: category.code,
            label: category.name
          }))
          .filter((category) => category.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) || inputValue.toLocaleLowerCase().includes(category.label.toLocaleLowerCase()));

        return options;
      }).catch((error) => {
        console.log(error);
      });
  };

  const offerDataAvailable = watch("offerDataAvailable");
  const saleDataAvailable = watch("saleDataAvailable");

  const kvIdToCopy = kvIdToCopyWatch("kvIdToCopy");

  const handleKvIdToCopyClick = async () => {
    const baseUrl = globalThis?.settings?.REACT_APP_API_ENDPOINT;

    let successful = false;

    if (
      kvIdToCopy !== "" &&
      kvIdToCopy !== undefined &&
      kvIdToCopy !== null
    ) {
      const kvIdToCopyNumber = Number(kvIdToCopy);

      if (
        !Number.isNaN(kvIdToCopyNumber) &&
        Number.isFinite(kvIdToCopyNumber)
      ) {
        const response = await fetch(`${baseUrl}/projects/${projectId}/contracts/${kvIdToCopyNumber}`, { credentials: "include" });

        if (response.ok) {
          const { payload: { contract } } = await response.json();

          if (contract) {
            const contractValues = await generateSaleEditValues(
              await transformContractToUnit(contract)
            );

            const nextUnitObject = {
              ...getValues(),
              ...contractValues
            };

            reset(nextUnitObject, { keepDirty: true });

            kvIdToCopyReset();

            setCopiedKvId(kvIdToCopyNumber);

            successful = true;
          }
        }
      }
    }

    if (!successful) {
      kvIdToCopySetError("kvIdToCopy", {
        message: "KV-ID im Projekt nicht vorhanden",
        type: "custom"
      });
    }
  };

  const kvIdToCopyOnSubmit = () => {
    handleKvIdToCopyClick();
  };

  useEffect(() => {
    if (kvIdToCopy === "") {
      setFocus("name");
    }
  }, [kvIdToCopy]);

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <div className="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <FormProvider {...kvIdToCopyForm}>
          <form
            className="absolute top-[76px]"
            onSubmit={kvIdToCopyHandleSubmit(kvIdToCopyOnSubmit)}
          >
            <div className="grid h-24 grid-cols-6 items-center">
              <div className="col-span-3" />

              <NumberField
                fixed
                int
                noprefix
                allowNegative={false}
                className="col-span-3"
                errorClassName="absolute -top-5 m-0! text-xs w-[150%]"
                innerClassName="relative"
                label="KV-ID"
                name="kvIdToCopy"
                thousandSeparator={false}
                {...{
                  control: kvIdToCopyFormControl,
                  errors: kvIdToCopyErrors,
                  register: kvIdToCopyRegister
                }}
                outerSuffix={
                  copiedKvId
                    ? (
                      <span className="absolute -bottom-1 right-6 text-xs text-gray-700">
                        Eingefügte KV-ID: {copiedKvId}
                      </span>

                    )
                    : null
                }
                suffix={(
                  <Button
                    className="ml-4"
                    disabled={kvIdToCopy === ""}
                    icon={FileOutputIcon}
                    onClick={handleKvIdToCopyClick}
                  />
                )}
              />
            </div>
          </form>
        </FormProvider>

        <FormProvider {...form}>
          <form
            autoComplete="off"
            className="flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" value="hidden" />

            <div className="flex flex-1 flex-col">

              <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Objekt ${unit ? "bearbeiten" : "erstellen"}`} />

              <div className="grid h-24 grid-cols-6 items-center">
                <CheckboxField
                  compact
                  sameHeightAsTextField
                  className="col-span-3"
                  label="Objekt im Produkt anzeigen"
                  name="show"
                  {...{
                    errors,
                    register
                  }}
                />

              </div>

              <div className="m-4 grid grid-cols-6 gap-x-4 bg-indigo-100 px-3 py-5">
                <div className="col-span-6">
                  <p className="text-sm font-bold text-indigo-600">Interne Sortierung</p>
                </div>

                <ComboField
                  async
                  loadDefaultComboOptions
                  label="Bauteil"
                  name="buildingPart"
                  options={loadBuildingPartOptions}
                  messages={{
                    queryEmpty: "Bauteil..."
                  }}
                  {...{
                    control,
                    isEdit,
                    resetField,
                    setValue,
                    watch
                  }}
                  compact
                  className="col-span-6"
                />

                <NumberField
                  noprefix
                  label="Stiege"
                  name="positionStaircase"
                  scale="none"
                  {...{
                    control,
                    errors,
                    register
                  }}
                  compact
                  className="col-span-3"
                  innerClassName="pr-0"
                />

                <NumberField
                  noprefix
                  label="Nummer"
                  name="position"
                  scale="none"
                  {...{
                    control,
                    errors,
                    register
                  }}
                  compact
                  className="col-span-3 ml-8"
                  innerClassName="pl-0"
                />

              </div>

              <ComboField
                async
                loadDefaultComboOptions
                label="Objektkategorie"
                name="unit_category"
                options={loadUnitCategoryOptions}
                messages={{
                  queryEmpty: "Objektkategorie..."
                }}
                {...{
                  control,
                  isEdit,
                  resetField,
                  setValue,
                  watch
                }}
                className="col-span-6"
              />

              <Field
                label="Name"
                name="name"
                {...{
                  errors,
                  register
                }}
              />

              <Field
                label="Stiege"
                name="staircase"
                {...{
                  errors,
                  register
                }}
              />

              <Field
                label="Geschoß"
                name="floor"
                {...{
                  errors,
                  register
                }}
              />

              <CheckboxField
                label="Maisonettewohnung"
                name="maisonette"
                {...{
                  errors,
                  register
                }}
              />

              <div
                className={cn("m-4 transition-all rounded-sm", {
                  "bg-primary-brown-light": offerDataAvailable,
                  "bg-white": !offerDataAvailable
                })}
              >
                <CheckboxField
                  label="Angebotsdaten vorhanden"
                  name="offerDataAvailable"
                  className={cn("transition-all transform", {
                    "-mx-4": !offerDataAvailable,
                    "mx-0": offerDataAvailable
                  })}
                  {...{
                    errors,
                    register
                  }}
                />

                {offerDataAvailable
                  ? (
                    <OfferForm
                      {...{
                        isEdit,
                        onSubmit
                      }}
                    />
                  )
                  : null}
              </div>

              <div
                className={cn("mx-4 mb-4 transition-all rounded-sm", {
                  "bg-primary-green-light": saleDataAvailable,
                  "bg-white": !saleDataAvailable
                })}
              >
                <CheckboxField
                  label="Objekt verkauft"
                  name="saleDataAvailable"
                  className={cn("transition-all transform", {
                    "-mx-4": !saleDataAvailable,
                    "mx-0": saleDataAvailable
                  })}
                  {...{
                    errors,
                    register
                  }}
                />

                {saleDataAvailable
                  ? (
                    <SaleForm
                      {...{
                        isEdit,
                        onSubmit,
                        unit
                      }}
                    />
                  )
                  : null}
              </div>
            </div>

            <div className="sticky bottom-0 flex shrink-0 items-center justify-between gap-4 border-t border-gray-200 bg-white px-4 py-5 sm:px-6">
              {
                unit?.documents && unit.documents.length > 0
                  ? (
                    <ul className="flex flex-wrap gap-2">
                      {
                        unit.documents.map((document) => (
                          <UnitDocumentItemSmall
                            document={document}
                            key={`document_unit_${document.id}`}
                          />
                        ))
                      }
                    </ul>
                  )
                  : null
              }

              <div className="ml-auto flex items-center justify-end gap-4">
                <CancelButton disabled={formIsLoading} onClick={handleClose} />

                <FormSubmitButton isSubmitting={formIsLoading}>
                  Speichern
                </FormSubmitButton>
              </div>
            </div>

          </form>

        </FormProvider>
      </div>
    </SlideOver>
  );
};

export default EditUnitSlide;
