import cn from "classnames";
import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import GoogleMapsIcon from "~/src/ui/icons/google-maps-icon/index.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import { handleDelete } from "./handlers.js";
import LocationSlide from "./location-slide.jsx";

/**
 *
 * @example
 */
const ProjectLocations = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: locationData, hide: hideNew, show: showSlide, visible: newVisible
  } = useModal();
  // const { visible: editVisible, show: showEdit, hide: hideEdit, data: location } = useModal()
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  return (
    <TabListContainer>
      <LocationSlide hide={hideNew} location={locationData} projectId={id} visible={newVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Adressen" />

        <DescriptionContainer>
          {project.locations?.map((locationData, index) => (

            <div
              key={`location_${locationData.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0,
                "border-2 border-indigo-700": locationData.default_location
              })}
            >

              {locationData.default_location
                ? (
                  <div className="col-span-4">
                    <p className="inline rounded-sm bg-indigo-300 px-1 py-0.5 text-sm font-medium text-indigo-700">Hauptadresse</p>
                  </div>
                )
                : null}

              <div className="col-span-3">
                <p className="text-sm font-medium text-gray-700">Straße</p>

                <p className="">{locationData.street.name}</p>
              </div>

              {showEditActions
                ? (
                  <div className="col-span-1 flex items-center justify-end">
                    <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showSlide(locationData)} type="button">
                      <EditIcon className="size-6" />
                    </button>

                    <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showDelete(locationData)} type="button">
                      <DeleteIcon className="size-6" />
                    </button>
                  </div>
                )
                : null}

              {!showEditActions &&
                <div className="col-span-1" />}

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Hausnummer</p>

                <p className="">{locationData.housenumber || "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">PLZ</p>

                <p className="">{locationData.street.city.zipcode}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Stadt</p>

                <p>{locationData.street.city.name}</p>
              </div>

              <div className="col-span-1 flex items-center">
                <a href={`https://google.com/maps/place/${locationData.lng},${locationData.lat}`} rel="noreferrer noopener" target="_blank">
                  <Button
                    className="scale-75 justify-self-start"
                    icon={GoogleMapsIcon}
                    label="Maps"
                  />
                </a>
              </div>
            </div>
          ))}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton
                  onClick={() => {
                    showSlide();
                  }}
                >
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectLocations;
