import cn from "classnames";
import { useParams } from "react-router-dom";

import { formatArea } from "~/src/modules/formatters.js";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import EinlageSlide from "./einlage-slide.jsx";
import { handleDelete } from "./handlers.js";

const ProjectEinlagen = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: einlageData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  return (
    <TabListContainer>

      <EinlageSlide einlage={einlageData} hide={hideSlide} projectId={id} visible={slideVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Projektliegenschaften" />

        <DescriptionContainer>
          {project.einlagen.map((einlage, index) => {
            const { br_katastralgemeinde: br_kg, katastralgemeinde: kg } = einlage;

            return (
              <div
                key={`einlage_${einlage.id}`}
                className={cn("grid grid-cols-4 gap-2 p-2", {
                  "bg-gray-100": index % 2 === 0
                })}
              >

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">BG</p>

                  <p className="">{kg.bezirksgericht.code} {kg.bezirksgericht.name}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">KG</p>

                  <p className="">{kg.code} {kg.name}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">EZ</p>

                  <p className="">{einlage.ez_number}</p>
                </div>

                {showEditActions
                  ? (
                    <div className="col-span-1 flex items-center justify-end">
                      <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showSlide(einlage)} type="button">
                        <EditIcon className="size-6" />
                      </button>

                      <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showDelete(einlage)} type="button">
                        <DeleteIcon className="size-6" />
                      </button>
                    </div>
                  )
                  : null}

                {!showEditActions &&
                  <div className="col-span-1" />}

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">GST-Nr.</p>

                  <p className="">{einlage.gst_numbers.join(", ")}</p>
                </div>

                <div className="col-span-3">
                  <p className="text-sm font-medium text-gray-700">Fläche</p>

                  <p>{formatArea(einlage.area)}</p>
                </div>

                {einlage.baurecht
                  ? (
                    <>

                      <div className="col-span-1">
                        <p className="text-sm font-medium text-gray-700">BG (Baurecht)</p>

                        <p className="">{br_kg.bezirksgericht.code} {br_kg.bezirksgericht.name}</p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-sm font-medium text-gray-700">KG (Baurecht)</p>

                        <p className="">{br_kg.code} {br_kg.name}</p>
                      </div>

                      <div className="bg- col-span-1">
                        <p className="-ml-1 inline rounded-sm bg-indigo-300 px-1 py-0.5 text-sm font-medium text-indigo-700">BREZ</p>

                        <p className="">{einlage.brez_number}</p>
                      </div>

                    </>
                  )
                  : null}

              </div>
            );
          })}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide()}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectEinlagen;
