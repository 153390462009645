import {
  Route,
  Switch,
  useParams
} from "react-router-dom";

import useProject from "~/src/hooks/use-project.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

import {
  ProjectUnitsListPage,
  ProjectUnitsStatisticsPage
} from "./project-units/_exports.js";

/**
 *
 * @example
 */
const ProjectUnitsPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <div className="-mt-24 pt-6 md:px-0">
      <Switch>
        <Route exact path="/projects/:id/project-units">
          <ProjectUnitsListPage />
        </Route>

        <Route exact path="/projects/:id/project-units/statistics">
          <ProjectUnitsStatisticsPage />
        </Route>
      </Switch>
    </div>

  );
};

export default ProjectUnitsPage;
