import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import EditorInput from "~/src/ui/forms/editor-input/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import CloseIcon from "~/src/ui/icons/close-icon.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./handlers.js";
import schema from "./schema.js";

const ResearchNotesSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError, showSuccess } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control, handleSubmit, reset
  } = useForm({
    defaultValues: { research_notes: project?.research_notes || "" },
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    reset({ research_notes: project?.research_notes });
  }, [project, reset]);

  const handleClose = () => {
    reset();
    hide();
  };

  const onSubmit = (data) => {
    handleUpdate({
      id: projectId,
      data,
      handleClose,
      mutate,
      setIsLoading,
      showError,
      showSuccess
    });
  };

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <div className="bg-gray-800 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2 className="text-lg font-medium text-white">
                  Externe Ressourcen bearbeiten
                </h2>
              </div>

              <div className="flex h-7 items-center">
                <button className="focus:outline-hidden rounded-md text-white hover:text-gray-100 focus:ring-2 focus:ring-indigo-500" onClick={handleClose} type="button">
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div className="px-4 sm:px-6 sm:py-5">
            <EditorInput
              control={control}
              name="research_notes"
              value={project?.research_notes}
            />
          </div>
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={isLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default ResearchNotesSlide;
