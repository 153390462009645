import cn from "classnames";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { formatArea } from "~/src/modules/formatters.js";

import Pagination from "~/src/features/pagination/index.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useAutomatedUnits from "~/src/hooks/use-automated-units.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useStore from "~/src/hooks/use-store.js";

import ScrollTopButton from "~/src/ui/buttons/scroll-top-button/index.jsx";
import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import ProjectUnit from "~/src/ui/project-unit/index.jsx";
import ProjectUnitCompact from "~/src/ui/project-unit-compact/index.jsx";
import Filter from "~/src/ui/search/filter/index.jsx";

import SearchUnits from "./search-units.jsx";
import UnitFormSlide from "./unit-form-slide.jsx";

const sortFilters = [
  {
    label: "Stiege/Nummer aufsteigend",
    value: "position-asc"
  },
  {
    label: "Stiege/Nummer absteigend",
    value: "position-desc"
  },
  {
    label: "Preis/m² Verkauf aufsteigend",
    value: "avg-sale-price-asc"
  },
  {
    label: "Preis/m² Verkauf absteigend",
    value: "avg-sale-price-desc"
  },
  {
    label: "Nutzfläche Verkauf aufsteigend",
    value: "sale-area-asc"
  },
  {
    label: "Nutzfläche Verkauf absteigend",
    value: "sale-area-desc"
  }
];

/**
 *
 * @example
 */
const ProjectUnits = () => {
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [tz, setTz] = useState("");
  const [sortFilter, setSortFilter] = useState("updated-at-desc");
  const [showHidden, setShowHidden] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentBuildingPart, setCurrentBuildingPart] = useState("");

  const { id } = useParams();
  const { user } = useAuth();
  const {
    automated_project: project, isError, isLoading, mutate: mutateProject
  } = useAutomatedProject(id);

  const viewFilter = useStore((state) => state.viewFilter);
  const setViewFilterCompact = useStore((state) => state.setViewFilterCompact);
  const setViewFilterDetail = useStore((state) => state.setViewFilterDetail);

  const {
    automatedUnits: units, categories, mutate: mutateUnits, pagination, unitStats
  } = useAutomatedUnits({
    bauteil: currentBuildingPart,
    category: currentCategory,
    hidden: showHidden,
    name,
    page,
    projectId: id,
    sort: sortFilter,
    tz,
    view: viewFilter
  });

  const { editPossible, editUnitPossible } = useEdit({
    automated: true,
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  useEffect(() => {
    mutateUnits();
  }, [currentCategory, mutateUnits]);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  /**
   *
   * @param value
   * @example
   */
  function toggleHidden(value) {
    setShowHidden(value);
    setPage(1);
  }

  const showEditActions = editPossible;
  const showEditUnitActions = editPossible;

  const visibilityKey = showHidden ? "hidden" : "shown";

  return (
    <div>
      <UnitFormSlide buildingParts={project?.building_parts} hide={hideSlide} mutateUnits={mutateUnits} projectId={id} unit={slideData} visible={slideVisible} />

      <div className="min-h-180 grid grid-cols-8 gap-4">
        <div className="col-span-5 flex flex-col gap-4 bg-gray-100 p-4 shadow-sm">
          <div className="flex w-full justify-between gap-4">
            <h2 className="text-xl font-medium text-gray-800">Objekte</h2>

            {
              unitStats
                ? (
                  <div className="flex items-end gap-4">
                    <span>Gesamtanzahl: </span>

                    <div className="flex items-center gap-4">
                      <span className="col-span-1 flex justify-end">{unitStats.count.total}</span>

                      <button
                        onClick={() => toggleHidden(!showHidden)}
                        className={cn("text-sm flex items-center px-2 bg-gray-300 rounded-sm focus:outline-hidden ", {
                          "bg-yellow-500 text-white": !showHidden
                        })}
                      >
                        <EyeIcon className="size-3.5 " />

                        <span className="ml-1.5">{unitStats.count.shown}</span>
                      </button>

                      <button
                        onClick={() => toggleHidden(!showHidden)}
                        className={cn("text-sm flex items-center px-2 bg-gray-300 rounded-sm focus:outline-hidden", {
                          "bg-yellow-500 text-white": showHidden
                        })}
                      >
                        <EyeOffIcon className="size-3.5 " />

                        <span className="ml-1.5">{unitStats.count.hidden}</span>
                      </button>

                    </div>

                  </div>
                )
                : null
            }
          </div>

          <ul className="grid grid-cols-4 gap-4">

            {unitStats
              ? Object.keys(unitStats).filter((key) => key !== "count").map((cat, index) => {
                const stat = unitStats[cat];

                return (
                  <li className="col-span-4 grid grid-cols-12 gap-4" key={`area_category_${index}`}>
                    <span className="col-span-3">{stat.name}:</span>

                    <span className="col-span-1 flex justify-end self-start">{stat.count.total}</span>

                    <div className="col-span-8 -mt-1.5 grid grid-cols-8 rounded-sm bg-white/50 p-2 text-sm">
                      <span className="col-span-2">Verkauf:</span>

                      <span className="col-span-6">{stat.saleArea ? `${formatArea(stat.saleArea[visibilityKey])} (${stat.saleAreaCount[visibilityKey]})` : "-"}</span>
                    </div>
                  </li>
                );
              })
              : null}
          </ul>
        </div>
      </div>

      <SearchUnits setName={setName} setPage={setPage} setTz={setTz} />

      <div className="sticky top-12 z-40 mt-8 border-b-2 border-amber-300 bg-amber-100 p-4 backdrop-blur-md">
        <div className="flex items-end justify-between">
          <div className="flex space-x-4">
            <Filter
              filter={sortFilter}
              label="Sortieren nach"
              options={sortFilters}
              setFilter={setSortFilter}
              setPage={setPage}
            />

            <Filter
              filter={currentCategory}
              label="Kategorie"
              setFilter={setCurrentCategory}
              setPage={setPage}
              options={categories?.map((uc) => ({
                label: uc.name,
                value: uc.code
              })) || []}
            />
          </div>

          <div className="ml-8 space-x-2 text-sm text-gray-800">
            <span>Ansicht:</span>

            <button
              onClick={setViewFilterCompact}
              type="button"
              className={cn("focus:outline-hidden", {
                "font-bold": viewFilter === "compact",
                underline: viewFilter !== "compact"
              })}
            >
              Kompakt
            </button>

            <button
              onClick={setViewFilterDetail}
              type="button"
              className={cn("focus:outline-hidden", {
                "font-bold": viewFilter !== "compact",
                underline: viewFilter === "compact"
              })}
            >
              Detail
            </button>
          </div>
        </div>

      </div>

      <div className="mt-4">
        <Pagination labels={["Objekt", "Objekte"]} page={page} pagination={pagination} setPage={setPage} />
      </div>

      <div
        className={cn("divide-y", {
          "space-y-6": viewFilter === "compact",
          "space-y-16": viewFilter !== "compact"
        })}
      >

        {units?.map((unit) => {
          if (viewFilter === "compact") {
            return (
              <div className="relative pt-6" key={`unit_${unit.id}`}>
                <div
                  className={cn("", {
                    "flex justify-between items-center": !unit.show
                  })}
                >
                  {!unit.show && (
                    <p className="inline-flex items-center rounded-sm bg-amber-500 px-2 py-0.5 text-xs text-white">
                      <EyeOffIcon className="size-3.5" />

                      <span className="ml-2">wird im Produkt nicht anzeigt</span>
                    </p>
                  )}

                  {showEditUnitActions
                    ? (
                      <div className="absolute -right-8 top-8 flex flex-col justify-start">
                        <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showSlide(unit)} type="button">
                          <EditIcon className="size-6" />
                        </button>
                      </div>
                    )
                    : null}
                </div>

                <ProjectUnitCompact
                  automated
                  showEditActions={showEditUnitActions}
                  unit={unit}
                />
              </div>
            );
          }

          return (
            <div className="pt-6" key={`unit_${unit.id}`}>
              <div
                className={cn("", {
                  "flex justify-between items-center": !unit.show
                })}
              >
                {!unit.show && (
                  <p className="inline-flex items-center rounded-sm bg-amber-500 px-2 py-0.5 text-xs text-white">
                    <EyeOffIcon className="size-3.5" />

                    <span className="ml-2">wird im Produkt nicht anzeigt</span>
                  </p>
                )}

                {showEditUnitActions
                  ? (
                    <div className="flex items-center justify-end">
                      <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-hidden" onClick={() => showSlide(unit)} type="button">
                        <EditIcon className="size-6" />
                      </button>
                    </div>
                  )
                  : null}
              </div>

              <ProjectUnit
                automated
                showEditActions={showEditUnitActions}
                unit={unit}
              />
            </div>
          );
        })}
      </div>

      <Pagination labels={["Objekt", "Objekte"]} page={page} pagination={pagination} setPage={setPage} />

      <div className="fixed bottom-4 right-4">
        <div className="space-x-2">
          {showEditUnitActions
            ? (
              <SubmitButton onClick={() => showSlide()}>
                <PlusIcon className="size-5" />
              </SubmitButton>
            )
            : null}

          <ScrollTopButton />
        </div>
      </div>

    </div>
  );
};

export default ProjectUnits;
