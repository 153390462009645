import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 * @import { LegacyHookResult } from "./_common/_exports.js";
 * @import { ProjectCertificate } from "../_common/project-certificate.doc.js";
 */

// TODO[2025-01-01]: Add missing properties to project type.

/**
 * The project object.
 *
 * @typedef {object} Project
 * @property {readonly ProjectCertificate[]} certificates
 */

/**
 * The project payload object.
 *
 * @typedef {object} ProjectPayload
 * @property {Project} project
 * @property {readonly unknown[]} transactionCategories
 */

/**
 * The project response object.
 *
 * @typedef {object} ProjectResponse
 * @property {ProjectPayload} payload
 */

/**
 * Custom hook to fetch and manage project data.
 *
 * @param {number} id - The project ID
 * @returns {LegacyHookResult<ProjectResponse>} An object containing the project data payload, SWR state and mutate function
 * @example
 * const {
 *  isError,
 *  isLoading,
 *  mutate,
 *  project,
 *  transactionCategories
 * } = useProject(id);
 */
const useProject = (id) => {
  const {
    data, error, mutate
  } = useSWR(`/projects/${id}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    project: data?.payload?.project,
    transactionCategories: data?.payload?.transaction_categories
  };
};

export default useProject;
